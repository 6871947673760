export default {
  title: 'Configuración de metas',
  subtitle: 'Configure las metas que deben conseguir los perfiles de la empresa',
  all: 'Todos',
  save_goal_invalid: 'Por favor corregir campos marcados con rojo',
  filter: {
    defaultOption: 'Seleccione',
    customerType: 'Tipo cliente',
    segment: 'Segmento'
  },
  table: {
    name: 'Nombre',
    role: 'Cargo',
    customer: 'Cliente',
    contactType: 'Tipo de Contacto',
    contactSegment: 'Segmento de Contacto',
    visits: 'Visitas',
    frequency: 'Frecuencia',
    period: 'Periodo',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de término',
    state: 'Estado'
  },

  goal: {
    all: 'Todos',
    states: {
      CREAD: 'Creado',
      VIGEN: 'Vigente',
      NOVIG: 'No Vigente'
    },
    periods: {
      MES: 'Mes'
    },

    action: {
      edit: 'Editar',
      play: 'Iniciar',
      delete: 'Eliminar'
    }
  },

  info_find_goal: 'Debe seleccionar segmento para buscar metas',

  create_goal_success: 'La meta ha sido creada satisfactoriamente',
  create_goal_warn_position: 'Debe seleccionar el campo Cargo',
  update_goal_success: 'La meta ha sido actualizada satisfactoriamente',
  delete_goal_success: 'La meta ha sido eliminada satisfactoriamente',
  delete_goal_confirm_alert: '¿Está seguro que desea eliminar la meta?',
  delete_goal_error_permission: 'Usuario sin privilegios para eliminar meta',

  start_goal_confirm_alert: '¿Está seguro que desea iniciar la meta?',
  start_goal_warn_start_date: 'Fecha inicio de la meta debe ser mayor al 1° del mes siguiente',
  start_goal_warn_end_date: 'Fecha término de la meta debe ser mayor a la fecha inicio',

  goal_not_found: 'No se encontraron metas',

  btn_confirm_label: 'Si',
  btn_cancel_label: 'No',

  add: 'Agregar Meta'
}
