export default {
  title: 'Acción',
  subtitle_new: 'Nueva acción',
  subtitle_created: 'Detalle acción',
  name: 'Descripción',
  change_name: 'Cambiar nombre',
  // subtitle: 'Complete los campos requeridos para crear una acción',
  planning: 'Fecha planificación',
  implementation: 'Fecha ejecucción',

  date: 'Fecha',
  duration: 'Duración',

  datatype: {
    description: 'Descripción',
    examine: 'Examinar',
    add_member: 'Añadir participante'
  },

  responsible: 'Responsable',
  user_responsible: 'Asignada a',
  save: 'Guardar',
  cancel: 'Cancelar',
  delete_action: 'Eliminar',

  add_reminder_alert: 'Fecha de acción es anterior a la fecha actual',
  delete_attachment_success: 'El documento adjunto ha sido eliminado satisfactoriamente',
  delete_attachment_error_permission: 'Usuario sin privilegios para eliminar adjunto',
  delete_action_success: 'La acción ha sido eliminada satisfactoriamente',
  delete_action_error_permission: 'Usuario sin privilegios para eliminar acción',
  create_reminder_action_id_alert: 'Debe guardar la acción para crear el recordatorio',
  create_action_success: 'La acción ha sido creada satisfactoriamente',
  create_attachment_success: 'El documento adjunto ha sido creado satisfactoriamente',
  create_attachment_invalid_type_error: 'Tipo de archivo inválido',
  update_customercontactability_invalid: 'Por favor corregir campos marcados con rojo',
  create_action_title_alert: 'Debe ingresar un nombre de acción para poder continuar',
  create_action_customer_alert: 'Debe seleccionar un cliente para continuar',
  create_action_type_alert: 'Debe selecionar un tipo para continuar',
  create_action_subtype_alert: 'Debe selecionar un subtipo para continuar',
  create_action_contact_alert: 'Debe selecionar un contacto para continuar',
  update_action_success: 'La acción ha sido actualizada satisfactoriamente',
  invalid_attachment_extension: 'Uno o varios archivos no se guardaron por tener una extensión inválida.<br> Se admiten las siguientes extensiones: jpg, jpeg, png, gif, docx, doc, xlsx, xls, pptx, ppt, msg, pdf.',
  delete_attachment_confirm_alert: '¿Está seguro que desea eliminar el documento adjunto del cliente?',
  delete_action_confirm_alert: '¿Está seguro que desea eliminar la acción del cliente?',
  find_user_alert: 'No se han encontrado datos del cliente',
  definition_not_found: 'No se ha encontrado definición para el tipo seleccionado',
  btn_confirm_label: 'Si',
  btn_cancel_label: 'No',

  options: {
    self: 'Mí',
    disabled: 'Usuario deshabilitado'
  },

  placeholder_datetime: 'Seleccione fecha y hora',
  now: 'Hoy',

  customer_title: 'Dirigida a',
  ticket_title: 'Asociada a',
  ticket_description: 'Número de Gestión ',
  action_name: '<%= actionType %> a <%= actionAssigned %>',

  info_title: 'Acción del tipo ',
  info_to_customer: 'Dirigida a ',
  info_to_document: 'Asociada a la Gestión N° ',
  info_reminder_view: 'Ver recordatorios',
  info_contact: ', contacto: ',

  subtitle_document: 'Asociada a la Gestión N° <%= documentId %>',
  subtitle_customer: 'Dirigida a <%= customerFullName %>',
  subtitle_contact: 'Dirigida a <%= contactFullName %> de cliente <%= customerFullName %>',
  characters_remaining: ' caracteres restantes'
}
