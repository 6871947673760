export default {
  title: 'Comentario',
  subtitle: 'Agregar comentario',
  placeholder: 'Haz click aquí para escribir un comentario...',
  characters_remaining: ' caracteres restantes',
  btn_save: 'Guardar',
  alert_create_comment: 'Debe ingresar el contenido del comentario para continuar',
  alert_max_comment: 'El contenido supera el largo máximo permitido',
  alert_create_comment_success: 'El comentario ha sido creado satisfactoriamente'
}
