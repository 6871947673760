export default {
  title: 'Búsqueda de contactos',
  contact_type: 'Tipo de contacto',
  document_type: 'Tipo de identificador',
  contact_id: 'Identificador',
  placeholder_contact_id: 'Ingrese',
  name: 'Nombre',
  placeholder_contact_name: 'Ingrese',

  contact_selection: 'Seleccione contacto',
  contact_not_found: 'No se encontraron contactos',
  placeholder_search: 'Buscar contacto',

  alert_contact_select: 'Debe seleccionar cliente para realizar la búsqueda',

  btn_search: 'Buscar'
}
