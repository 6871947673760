<template>
  <div class="image-cropper">
    <div class="box">
      <h6 class="title is-6 is-uppercase has-text-weight-bold">
        {{ titleCropper }}
      </h6>

      <div class="columns">
        <div
          v-if="roundedPreview"
          class="column is-1"
        >
          <figure class="image is-64x64">
            <img
              class="is-rounded"
              :src="crop"
              :alt="text.image_placeholder"
            >
          </figure>
        </div>

        <div class="column">
          <div class="columns is-multiline">
            <div class="column is-12">
              <Cropper
                :src="image"
                :stencil-props="{
                  aspectRatio: 1
                }"
                @change="onChangeCropper"
              />
            </div>
            <div class="column is-12">
              <div class="buttons is-centered">
                <button
                  class="button is-info"
                  @click="onCropImage"
                >
                  {{ text.ok }}
                </button>
                <button
                  class="button is-light"
                  @click="onCancelCrop"
                >
                  {{ text.cancel }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'ImageCropper',
  components: {
    Cropper
  },
  props: ['image', 'title', 'roundedPreview'],
  data () {
    return {
      crop: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      }
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].cropper
    },
    titleCropper () {
      return this.title ? this.title : this.text.title
    }
  },
  methods: {
    onChangeCropper ({ coordinates, canvas }) {
      this.coordinates = coordinates
      this.crop = canvas.toDataURL()
    },
    onCropImage () {
      this.$emit('cropImage', {
        crop: this.crop
      })
    },
    onCancelCrop () {
      this.$emit('cancelCrop')
    }
  }
}
</script>
