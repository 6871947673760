<script setup>
import { computed, defineAsyncComponent, defineEmits, defineProps, inject, onMounted, ref, watch } from 'vue'
import { useTicketStore } from '@/stores/ticket'
import { useTextStore } from '@/stores/text'

const TextAreaSidebar = defineAsyncComponent(() => import(/* webpackChunkName: "ticket" */ './TextAreaSidebar.vue'))

const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  definition: {
    type: Object,
    default: () => {}
  },
  alert: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input'])

const $alert = inject('$alert')

const ticketStore = useTicketStore()
const textStore = useTextStore()

const input = ref('')
const internalValue = ref('')
const isActive = ref(false)

const text = computed(() => textStore.current.awk.textarea)

const classes = computed(() => {
  const classes = []
  if (alert.value) {
    classes.push('is-danger')
  }
  return classes
})

const onChange = (e) => {
  internalValue.value = e
  input.value = e
}

const onToggleTextArea = () => {
  if (ticketStore.isNew) {
    $alert.info(text.value.comment_ticket_new)
    return false
  }

  isActive.value = !isActive.value
}

onMounted(() => {
  input.value = props.value
})

watch(
  () => input,
  () => {
    internalValue.value = input.value
  }
)

watch(
  () => internalValue,
  () => {
    emit('input', internalValue.value)
  }
)

watch(
  () => props.value,
  () => {
    input.value = props.value
  }
)

</script>

<template>
  <div class="control">
    <textarea
      v-model="input"
      class="textarea is-cursor-pointer has-background-light"
      :class="classes"
      rows="2"
      readonly="true"
      @click="onToggleTextArea"
    />

    <TextAreaSidebar
      v-if="isActive"
      :isActive="isActive"
      :definition="definition"
      :value="input"
      @change="onChange"
      @cancel="onToggleTextArea"
    />
  </div>
</template>
