export default {
  refresh: {
    pull_to: 'Desliza hacia abajo para refrescar',
    release_to: 'Suelta para refrescar',
    refreshing: 'Refrescando',
    iconArrow: '<i class="fa-solid fa-arrow-down" />',
    iconRefreshing: '<i class="fa-solid fa-arrows-rotate fa-spin" />'
  },
  title_filters: 'Historia',
  opt_mine: 'Mis gestiones',
  opt_served: 'Gestiones atendidas',
  opt_group_served: 'Grupo atendido <%= name %>',
  opt_group: 'Grupo <%= name %>',
  opt_company: 'Empresa',
  subtitle_filters: 'Aquí lo tienes, tus gestiones organizadas',

  showing_ticket_msg: '<span>Gestiones para el identificador <span class="has-text-weight-bold"><%= ticket %></span></span>',
  showing_mine_dates_msg: '<span><span class="has-text-weight-bold">Mis gestiones</span> entre el <%= start %> y el <%= end %></span>',
  showing_dates_msg: '<span>Gestiones <span class="has-text-weight-bold"><%= view %></span> entre el <%= start %> y el <%= end %></span>',

  lbl_filter: 'Filtrar',
  title_search: 'Búsqueda',
  btn_close: 'Cerrar',
  lbl_ticket_search: 'Buscar por identificador',
  placeholder_ticket: 'Ej: 1234567-8',
  or: 'ó',
  lbl_other_search: 'Buscar por otros parámetros',
  showing_results: 'Ver <%= quantity %> resultados',
  showing_max: 'Mostrar un máximo de',
  records_options: [
    {
      key: 5,
      value: '5 registros'
    },
    {
      key: 10,
      value: '10 registros'
    },
    {
      key: 25,
      value: '25 registros'
    },
    {
      key: 50,
      value: '50 registros'
    }
  ],
  records: 'registros',
  btn_advanced_filters: 'Filtros avanzados',
  btn_clean_filters: 'Limpiar filtros',
  btn_update: 'Refrescar',
  title_advanced_filters: 'Filtros avanzados',
  title_clean_filters: 'Limpiar filtros',
  title_update: 'Refrescar',

  lbl_dates: 'Rango de fechas',
  lbl_plan: 'Plan',
  lbl_process: 'Proceso',
  lbl_motive1: 'Motivo 1',
  lbl_motive2: 'Motivo 2',
  lbl_motive3: 'Motivo 3',
  lbl_term1: 'Término 1',
  lbl_term2: 'Término 2',
  lbl_term3: 'Término 3',
  lbl_cod_customer_type: 'Tipo de cliente',
  lbl_cod_customer_id: 'Tipo de documento',
  lbl_customer_id: 'Identificador',
  lbl_customer_name: 'Nombre o descriptor',
  lbl_cod_contact_type: 'Tipo de contacto',
  lbl_cod_contact_id: 'Tipo de documento',
  lbl_contact_id: 'Identificador',
  lbl_contact_name: 'Nombre',
  lbl_contact_lastname: 'Apellido paterno',
  lbl_status: 'Estado',
  lbl_search_in: 'Buscar en',
  search_options: {
    A: 'Línea',
    H: 'Historia'
  },
  btn_apply: 'Aplicar filtros',

  lbl_today: 'Hoy',
  lbl_previous: 'Anteriores',

  opt_all: 'Todos',
  opt_none: 'Ninguno',
  opt_select: 'Seleccione',

  not_found_title: 'No se encontraron resultados',
  not_found_subtitle: 'Intenta modificar o quitar los filtros de búsqueda',

  item: {
    ticket: 'Gestión',
    created: 'La gestión fue creada el',
    finished: ', con término el',
    open: 'Abierto',
    close: 'Cerrado'
  },

  detail: {
    select_ticket_msg: 'Selecciona un resultado para ver más detalles o ejecutar acciones',
    subject: 'Asunto',
    title: 'Gestión',
    process: 'Proceso',
    customer: 'Cliente',
    contact: 'Contacto',
    motives: 'Motivos',
    terms: 'Términos',
    about_ticket: 'Plazo gestión',
    dates: '<span class="is-flex is-justify-content-space-between"><span class="todo-date has-text-weight-bold"><%= from %></span> <span class="todo-arrow icon has-text-grey-light"><i class="fas fa-arrow-circle-right"></i></span> <span class="todo-date-right has-text-weight-bold"><%= until %></span></span>',
    status: '<span class="tag is-primary"><%= statusDescription %></span>',
    open: 'Abierto',
    close: 'Cerrado',
    btn_open: 'Abrir ticket'
  }
}
