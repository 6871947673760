import { each } from 'lodash'
import axios from 'axios'
import config from '@/config'
import { useErrorStore } from '@/stores/error'

const prefix = 'auris-'

const Session = {
  currentSession: null,
  currentSessionId: null,
  timer: null,
  keycloak: null,

  save (session) {
    if (sessionStorage) {
      sessionStorage.setItem(`${prefix}sesion`, JSON.stringify(session))
    }
    this.currentSession = session
  },

  get () {
    let session = null
    if (!sessionStorage) {
      session = this.currentSession
    } else {
      const jsonSession = sessionStorage.getItem(`${prefix}sesion`)
      if (jsonSession) {
        session = JSON.parse(jsonSession)
      }
    }
    return session
  },

  id (sessionId) {
    if (sessionId) {
      if (!localStorage) {
        this.currentSessionId = sessionId
      } else {
        localStorage.setItem(`${prefix}sid`, sessionId)
      }
    } else if (!localStorage) {
      sessionId = this.currentSessionId
    } else {
      sessionId = localStorage.getItem(`${prefix}sid`)
    }

    return sessionId
  },

  apiKey (apiKey) {
    if (apiKey) {
      if (!localStorage) {
        this.currentapiKey = apiKey
      } else {
        localStorage.setItem(`${prefix}rsid`, apiKey)
      }
    } else if (!localStorage) {
      apiKey = this.currentapiKey
    } else {
      apiKey = localStorage.getItem(`${prefix}rsid`)
    }

    return apiKey
  },

  hasPermission (permission) {
    const permissions = this.get().permissions

    return permissions[permission]
  },

  clear () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    const apiKey = this.apiKey()

    if (apiKey) {
      axios({
        method: 'delete',
        url: `${config.session.endpoint}/api/session`,
        headers: {
          Authorization: apiKey
        }
      })
        .catch(() => {
          // ignore error
        })
    }

    if (localStorage) {
      sessionStorage.removeItem(`${prefix}sesion`)
    }
    if (sessionStorage) {
      localStorage.removeItem(`${prefix}sid`)
      localStorage.removeItem(`${prefix}rsid`)
      localStorage.removeItem(`${prefix}rfid`)
      localStorage.removeItem(`${prefix}seid`)
    }
  },

  validate (callback) {
    const token = this.apiKey()
    if (!token) {
      return callback(null, { errorCode: 1 })
    }

    const http = this.http('session')

    http({
      method: 'get',
      url: '/session'
    })
      .then(response => {
        const data = response.data
        if (data.errorNum < 0) {
          // 'Su sesión no existe o ha sido reemplazado por otro usuario'
          return callback(null, { errorCode: 2 })
        } else if (data.errorNum !== 0) {
          return callback(null, { errorCode: 3 })
        } else {
          const session = data.data.session

          session.permissions = data.data.permissions

          this.save(session)
          if (!this.timer) {
            this.timer = setInterval(Session.getSession, 60000)
          }

          return callback(session)
        }
      })
      .catch((e) => callback(null, { errorCode: 4, source: e }))
  },

  getSession () {
    Session.validate((session, error) => {
      if (!session) {
        Session.clear()
        // window.location.reload()
      }
    })
  },

  permission (expression, hasCondition) {
    let hasPermission = false
    if (!hasCondition) {
      hasPermission = Session.hasPermission(expression)
    } else {
      const args = expression.split(' ')
      let flagOr = false
      let flagAnd = false
      each(args, elm => {
        switch (elm) {
          case 'or':
            flagOr = true
            flagAnd = false
            break

          case 'and':
            flagAnd = true
            flagOr = false
            break

          default:
            if (flagOr) {
              hasPermission = hasPermission || Session.hasPermission(elm)
            } else if (flagAnd) {
              hasPermission = hasPermission && Session.hasPermission(elm)
            } else {
              hasPermission = Session.hasPermission(elm)
            }
        }
      })
    }
    return hasPermission
  },

  http (configEndpoint, configEndpointChild) {
    const headers = {
      Authorization: `Bearer ${Session.apiKey()}`
    }

    if (configEndpoint) {
      let baseURL = config[configEndpoint].endpoint

      if (configEndpointChild) {
        baseURL = config[configEndpoint][configEndpointChild].endpoint
      }

      const create = axios.create({ baseURL, headers })

      create.interceptors.request.use(
        async request => {
          if (this.keycloak?.isTokenExpired(60)) {
            const result = await this.keycloak.updateToken(60)
            console.log('token refreshed', result)
            if (result) {
              this.id(this.keycloak.tokenParsed.session_state)
              this.apiKey(this.keycloak.token)
              request.headers.Authorization = `Bearer ${this.keycloak.token}`
            }
          }
          return request
        },
        error => Promise.reject(error)
      )

      create.interceptors.response.use(
        response => response,
        error => {
          const errorStore = useErrorStore()
          errorStore.addError(error)
          return Promise.reject(error)
        }
      )
      return create
    }

    const create = axios.create({ headers })

    create.interceptors.request.use(
      async request => {
        if (this.keycloak?.isTokenExpired(60)) {
          const result = await this.keycloak.updateToken(60)
          console.log('token refreshed', result)
          if (result) {
            this.id(this.keycloak.tokenParsed.session_state)
            this.apiKey(this.keycloak.token)
            request.headers.Authorization = `Bearer ${this.keycloak.token}`
          }
        }
        return request
      },
      error => error
    )

    create.interceptors.response.use(
      response => response,
      error => {
        const errorStore = useErrorStore()
        errorStore.addError(error)
        return Promise.reject(error)
      }
    )
    return create
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$session = Vue.observable(Session)
    Vue.session = Session
    Vue.prototype.$permission = Session.permission
    Vue.permission = Session.permission
  }
}
