export default {
  title: 'Buscador',
  btn_close: 'Cerrar',
  placeholder: '¿En qué te podemos ayudar?',
  contents: 'Temas',
  categories: 'Categorías',
  tags: 'Etiquetas',
  no_results_found: 'No se encontraron resultados',
  btn_view_more: 'Ver más'
}
