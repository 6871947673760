import Vue from 'vue'

import AutoComplete from './AutoComplete'
import AutosizeTextarea from './AutosizeTextarea'
import CollapsibleContainer from './CollapsibleContainer'
import DataList from './DataList'
import Dropdown from './Dropdown'
import DropdownSearch from './DropdownSearch'
import DropdownSubFlow from './DropdownSubFlow'
import Icon from './Icon'
import ImageCropper from './ImageCropper'
import Input from './Input'
import Checkbox from './Checkbox'
import Loading from './Loading'
import MultipleSelect from './MultipleSelect'
import NotFound from './NotFound'
import Pager from './Pager'
import SelectFinder from './SelectFinder'
import SidebarFloat from './SidebarFloat'
import SidebarList from './sidebarList'
import TextArea from './TextArea'
// Button
import BasicButton from './button/BasicButton'
import CloseButton from './button/CloseButton'
import StatusButton from './button/StatusButton'
// Date time picker
import Calendar from './Calendar'
import CalendarPicker from './calendar/CalendarPicker'
import DateRangePicker from './calendar/DateRangePicker'
import TimePicker from './calendar/TimePicker'
import TimeInput from './TimeInput'

Vue.component('AutoComplete', AutoComplete)
Vue.component('AutosizeTextarea', AutosizeTextarea)
Vue.component('CollapsibleContainer', CollapsibleContainer)
Vue.component('DataList', DataList)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownSearch', DropdownSearch)
Vue.component('DropdownSubFlow', DropdownSubFlow)
Vue.component('Icon', Icon)
Vue.component('ImageCropper', ImageCropper)
Vue.component('Input', Input)
Vue.component('Checkbox', Checkbox)
Vue.component('Loading', Loading)
Vue.component('MultipleSelect', MultipleSelect)
Vue.component('NotFound', NotFound)
Vue.component('Pager', Pager)
Vue.component('SelectFinder', SelectFinder)
Vue.component('SidebarFloat', SidebarFloat)
Vue.component('SidebarList', SidebarList)
Vue.component('TextArea', TextArea)
// Button
Vue.component('BasicButton', BasicButton)
Vue.component('CloseButton', CloseButton)
Vue.component('StatusButton', StatusButton)
// Date time picker
Vue.component('Calendar', Calendar)
Vue.component('DateRangePicker', DateRangePicker)
Vue.component('CalendarPicker', CalendarPicker)
Vue.component('TimePicker', TimePicker)
Vue.component('TimeInput', TimeInput)
