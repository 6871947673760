import detail from './detail'

export default {
  detail: detail,

  title: 'Etiquetas',
  btn_close: 'Cerrar',
  explore_tags: 'Explorar etiquetas',
  come_back_later: 'Por favor, vuelve más tarde'
}
