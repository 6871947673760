import filters from './filters'
import header from './header'
import detail from './detail'
import month from './month'
import dashboard from './dashboard'
import goal from './goal'
import plan from './plan'
import colors from './colors'

export default {
  filters: filters,
  header: header,
  detail: detail,
  month: month,
  dashboard: dashboard,
  goal: goal,
  plan: plan,
  colors: colors
}
