<template>
  <div
    class="dropdown"
    :class="{'is-active': isActive, 'is-right': isRight }"
  >
    <div
      class="dropdown-trigger is-cursor-pointer has-width-100"
      @click.prevent.stop="onToggle"
    >
      <button
        v-if="isButton"
        :class="dropdownClasses"
        :disabled="disabled"
      >
        <span>{{ internalDescription }} </span>
      </button>
      <span
        v-else
        class="icon-text"
      >
        <span
          v-if="hasIcon && !isIconRight"
          class="icon"
        >
          <i :class="icon" />
        </span>
        <span>{{ internalDescription }} </span>
        <span
          v-if="hasIcon && isIconRight"
          class="icon"
        >
          <i :class="icon" />
        </span>
      </span>
    </div>
    <div
      v-if="items"
      id="dropdown-menu"
      class="dropdown-menu has-text-weight-normal"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          v-if="title"
          class="dropdown-item has-text-weight-bold is-cursor-default"
          @click.prevent.stop
        >{{ title }}</a>
        <template v-for="item in items">
          <hr
            v-if="!isFirst(item.key)"
            :key="`div${item.key}`"
            class="dropdown-divider my-0"
          >
          <a
            :key="`item${item.key}`"
            class="dropdown-item"
            @click="onSelected(item.key)"
          >
            {{ item.value }}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { find, first } from 'lodash'

export default {
  name: 'Dropdown',
  model: {
    events: 'input'
  },
  props: {
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasIcon: Boolean,
    icon: {
      type: String,
      default: 'fa-solid fa-chevron-down'
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator (val) {
        return ['left', 'right'].includes(val)
      }
    },
    isButton: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    },
    items: Array,
    title: {
      type: String,
      default: ''
    },
    value: [String, Number]
  },
  data () {
    return {
      internalValue: '',
      isActive: false
    }
  },
  computed: {
    dropdownClasses () {
      if (this.isButton) {
        return [
          'button is-fullwidth',
          this.isActive ? 'is-dark' : 'is-light'
        ]
      }
      return ''
    },
    internalDescription () {
      const item = find(this.items, { key: this.value })
      return this.description || (item ? item.value : '')
    },
    isIconRight () {
      return this.iconPosition === 'right'
    }
  },
  watch: {
    value () {
      this.internalValue = this.value
    },
    internalValue () {
      this.$emit('input', this.internalValue)
    }
  },
  created () {
    this.internalValue = this.value
  },
  methods: {
    onToggle (event) {
      if (this.disabled) {
        return
      }

      this.isActive = !this.isActive
      if (this.isActive) {
        const callback = e => {
          this.isActive = false
          window.removeEventListener('click', callback)
        }
        window.addEventListener('click', callback)
      }
    },
    isFirst (key) {
      return first(this.items).key === key
    },
    onSelected (key) {
      this.internalValue = key
    }
  }
}
</script>
