<template>
  <div class="control">
    <input
      :id="`checkboxVariable_${dataId}`"
      v-model="input"
      type="checkbox"
      :name="`checkboxVariable_${dataId}`"
      class="switch is-rounded is-info"
    >
    <label :for="`checkboxVariable_${dataId}`" />
  </div>
</template>

<script>

export default {
  name: 'Checkbox',
  props: [
    'value',
    'dataId'
  ],
  data () {
    return {
      input: false
    }
  },
  watch: {
    input () {
      this.$emit('input', this.input ? 'S' : 'N')
    },
    value () {
      this.input = this.value === 'S'
    }
  },
  created () {
    this.input = this.value === 'S'
  }
}
</script>
