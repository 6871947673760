// import variables from '@/sass/variables.scss'
const variables = {
  tablet: 769,
  desktop: 1024,
  widescreen: 1216,
  fullhd: 1408
}

const responsive = {
  isMobile (screenWidth) {
    if (!screenWidth) {
      screenWidth = screen.width
    }
    return screenWidth < parseInt(variables.tablet)
  },
  isTablet (screenWidth) {
    if (!screenWidth) {
      screenWidth = screen.width
    }
    return screenWidth >= parseInt(variables.tablet) && screenWidth < parseInt(variables.desktop)
  },
  isDesktop (screenWidth) {
    if (!screenWidth) {
      screenWidth = screen.width
    }
    return screenWidth >= parseInt(variables.desktop) && screenWidth < parseInt(variables.widescreen)
  },
  isWidescreen (screenWidth) {
    if (!screenWidth) {
      screenWidth = screen.width
    }
    return screenWidth >= parseInt(variables.widescreen) && screenWidth < parseInt(variables.fullhd)
  },
  isFullhd (screenWidth) {
    if (!screenWidth) {
      screenWidth = screen.width
    }
    return screenWidth < parseInt(variables.fullhd)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$responsive = responsive
    Vue.responsive = responsive
  }
}
