<template>
  <div class="awk-select-finder">
    <div
      class="awk-select-finder__select"
      :class="[isOpen ? 'select--open' : 'select--close']"
      @click="toggleFinder"
    >
      <input
        :value="selectedLabel"
        disabled
      >
    </div>
    <div
      v-if="isOpen"
      class="awk-select-finder__finder"
    >
      <div class="awk-select-finder__finder__searchbox">
        <input
          ref="searchInput"
          v-model="searchText"
          :placeholder="validPlaceholder"
        >
      </div>
      <div class="awk-select-finder__finder__results">
        <label
          v-for="(availableItem, key) in availableItems"
          :key="key"
          class="awk-select-finder__finder__results__item"
          :class="[key === internalValue ? 'awk-select-finder__finder__results__item--active': '']"
          @click.prevent="selected(key)"
        >
          {{ availableItem }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { pickBy } from 'lodash'

export default {
  name: 'SelectFinder',
  components: { },
  model: {
    events: 'input'
  },
  props: ['value', 'items', 'placeholder'],
  data () {
    return {
      internalValue: null,
      searchText: '',
      isOpen: false
    }
  },
  computed: {
    selectedLabel () {
      let item = null
      if (this.items) {
        item = this.items[this.value]
      }
      return item
    },
    availableItems () {
      let availableItems = []
      if (this.items) {
        availableItems = pickBy(this.items, item => item.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
      }
      return availableItems
    },
    validPlaceholder () {
      return this.placeholder ? this.placeholder : ''
    }
  },
  watch: {
    value (value) {
      this.internalValue = value
    },
    internalValue (value) {
      this.$emit('input', value)
    },
    isOpen () {
      this.searchText = ''
    }
  },
  methods: {
    toggleFinder () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => this.$refs.searchInput.focus())
      }
    },
    selected (item) {
      this.isOpen = false
      this.internalValue = item
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../sass/_variables.scss';

  .awk-select-finder {

    &__select {
      display: block;

      input {
        background-color: white;

        &:after {
          content: '\f078';
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          margin-left: 10px;
        }
      }
    }
    &__finder {
      display: block;
      background-color: white;
      margin-top: .1em;

      &__searchbox {
        padding: 0 0;
      }
      &__results {
        max-height: 18em;
        overflow: auto;
        font-size: 1.5rem;

        &__item {
          display: block;
          cursor: pointer;
          padding: .375rem .75rem;

          &:hover {
            background-color: rgba($color-text, 0.1);
            color: $color-btn-link;
            text-decoration: none;
          }

          &--active {
            background-color: $color-btn-link;
            color: white;
          }
        }
      }
    }
  }
</style>
