export default {
  title: 'Recordatorios',
  subtitle: 'Recordatorios de la acción',
  add_reminder: 'Añadir recordatorio',
  no_scheduled_reminders: 'Sin recordatorios programados',
  new_reminder: 'Nuevo Recordatorio',
  name: 'Nombre',
  frequency: 'Frecuencia',
  five_minutes_before: '5 minutos antes',
  thirty_minutes_before: '30 minutos antes',
  hour_before: '1 hora antes',
  day_before: '1 día antes',
  personalized: 'Personalizado',
  date: 'Fecha',
  reminder_name: 'Nombre del recordatorio',
  when: 'Cuanto antes',
  before: 'antes',

  placeholder_date: 'Seleccione fecha y hora',
  now: 'Hoy',

  create_reminder_action_date_alert: 'Fecha de acción es anterior a la fecha actual',
  create_reminder_action_must_be_saved_alert: 'Debe guardar la acción para crear el recordatorio',
  create_reminder_success: 'El recordatorio ha sido creado satisfactoriamente',
  delete_reminder_success: 'El recordatorio ha sido eliminado satisfactoriamente',
  delete_reminder_confirm_alert: '¿Está seguro que desea eliminar el recordatorio?',

  confirm: {
    ok: 'Si',
    cancel: 'No'
  },

  save: 'Guardar'
}
