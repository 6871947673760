<template>
  <section
    class="hero is-fullheight is-medium-tablet"
    :class="{ 'is-fullheight-parent': fullHeightParent }"
  >
    <div class="hero-body">
      <div class="not-found-container">
        <p class="title">
          <img
            :src="image"
            alt="not found"
          >
        </p>
        <p class="subtitle is-6 has-text-centered mb-1">
          <b>{{ valTitle }}</b>
          <br>
          {{ valSubTitle }}
        </p>
        <slot />
      </div>
    </div>
  </section>
</template>

<script>

const images = [
  '/images/auris-img/10106-3.png',
  '/images/auris-img/10108-3.png',
  '/images/auris-img/10172-3.png',
  '/images/auris-img/10178-2.png',
  '/images/auris-img/10199-2.png',
  '/images/auris-img/10590.png',
  '/images/auris-img/10706.png',
  '/images/auris-img/10842.png',
  '/images/auris-img/10888.png',
  '/images/auris-img/10891.png',
  '/images/auris-img/10909.png',
  '/images/auris-img/11000.png',
  '/images/auris-img/11003.png',
  '/images/auris-img/11037.png',
  '/images/auris-img/11104.png',
  '/images/auris-img/11141.png',
  '/images/auris-img/11145.png',
  '/images/auris-img/11211.png',
  '/images/auris-img/11264.png',
  '/images/auris-img/11276.png',
  '/images/auris-img/11297.png',
  '/images/auris-img/13018.png',
  '/images/auris-img/13071.png',
  '/images/auris-img/13092.png',
  '/images/auris-img/13151.png',
  '/images/auris-img/13211.png',
  '/images/auris-img/136.png',
  '/images/auris-img/1736.png',
  '/images/auris-img/1741.png',
  '/images/auris-img/1752.png',
  '/images/auris-img/2518.png',
  '/images/auris-img/2538.png',
  '/images/auris-img/2539.png',
  '/images/auris-img/280.png',
  '/images/auris-img/4434.png',
  '/images/auris-img/4782.png',
  '/images/auris-img/4818.png',
  '/images/auris-img/514.png',
  '/images/auris-img/5225.png',
  '/images/auris-img/5230.png',
  '/images/auris-img/5259.png',
  '/images/auris-img/526.png',
  '/images/auris-img/5267.png',
  '/images/auris-img/5287.png',
  '/images/auris-img/5602.png',
  '/images/auris-img/5720.png',
  '/images/auris-img/5860.png',
  '/images/auris-img/5903.png',
  '/images/auris-img/5914.png',
  '/images/auris-img/5972.png',
  '/images/auris-img/6594.png',
  '/images/auris-img/6616.png',
  '/images/auris-img/6963.png',
  '/images/auris-img/7044.png',
  '/images/auris-img/7946.png',
  '/images/auris-img/8440.png',
  '/images/auris-img/8487.png',
  '/images/auris-img/8550.png',
  '/images/auris-img/856.png',
  '/images/auris-img/8588.png',
  '/images/auris-img/8630.png',
  '/images/auris-img/8692.png',
  '/images/auris-img/8749.png',
  '/images/auris-img/9800.png',
  '/images/auris-img/9847.png',
  '/images/auris-img/Cheerful-man-sharing-new-idea-with-friend.png',
  '/images/auris-img/Female-designer-working-late-in-room-flat-vector-illustration.png',
  '/images/auris-img/Happy-guy-developing-project-for-startup.png',
  '/images/auris-img/Happy-man-watching-launch-of-rocket.png',
  '/images/auris-img/Happy-travel-photographer-taking-picture-of-nature.png',
  '/images/auris-img/Managers-working-on-project-together.png',
  '/images/auris-img/Man-using-laptop-for-video-call.png',
  '/images/auris-img/Mom-and-daughter-comforting-upset-dad.png',
  '/images/auris-img/Tiny-cute-children-learning-coding.png',
  '/images/auris-img/Tiny-presenter-explaining-statistics-to-colleagues.png',
  '/images/auris-img/Two-female-colleagues-discussing-work.png',
  '/images/auris-img/Young_entrepreneurs_insist_data_for_digital_marketing,_advertising_on_the_Internet__Flat_2D_character__Concept_for_web_design.png'
]

export default {
  name: 'NotFound',
  components: { },
  props: ['title', 'subtitle', 'fullHeightParent'],
  data () {
    return {
      image: '/images/looking_1920.png'
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].todo.home
    },
    valTitle () {
      return this.title ?? this.text.not_found_title
    },
    valSubTitle () {
      return this.subtitle ?? this.text.not_found_subtitle
    }
  },
  watch: { },
  created () {
    const counterIdx = this.$counter.increase('NotFound')
    this.image = images[counterIdx % images.length]
  },
  methods: { }
}
</script>
