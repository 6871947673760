import { defineStore } from 'pinia'
import each from 'lodash/each'

export const useTicketStore = defineStore('ticket', {
  state: () => {
    return {
      isNew: false,
      isBackup: false,
      ticketId: '',
      companyId: 0,
      contactId: 0,
      serialId: 0,
      processId: '',
      campaignIdLogin: 0,
      taskId: '',
      assignedUsername: '',
      assignmentType: '',
      document: null,
      comment: '',
      dni: '',
      acd: 0,
      callId: '',
      ani: '',
      motiveId1: 0,
      motiveId2: 0,
      motiveId3: 0,
      termId1: 0,
      termId2: 0,
      termId3: 0,
      motiveReference: '',
      motiveGloss: '',
      termReference: '',
      termGloss: '',
      branchOfficeId: '',
      branchOffice: '',
      customerBranchOffice: '',
      ticketIndicator: '',
      resultIndicator: '',
      resultDate: '',
      creationDate: '',
      planId: 0,
      groupId: 0,
      userId: 0,
      mediaCode: '',
      bofCode: '',
      caseId: 0,
      userBranchOfficeId: '',
      productCode: '',
      productReference: '',
      flagAttachment: false,
      flagChecklist: false,

      fixedsData: {
        17: '',
        18: '',
        19: '',
        20: '',
        33: '',
        34: '',
        35: '',
        36: '',
        37: '',
        39: '',
        41: '',
        42: '',
        43: '',
        44: '',
        45: '',
        46: '',
        47: '',
        48: '',
        49: '',
        50: '',
        51: '',
        52: '',
        53: '',
        54: '',
        55: '',
        56: '',
        57: '',
        58: '',
        59: '',
        60: '',
        61: '',
        62: '',
        63: '',
        64: '',
        65: '',
        66: '',
        67: '',
        68: '',
        69: '',
        70: '',
        71: '',
        72: '',
        73: '',
        74: '',
        75: '',
        76: '',
        77: '',
        78: '',
        79: '',
        81: '',
        82: '',
        83: '',
        84: '',
        85: '',
        86: '',
        87: '',
        88: '',
        89: '',
        90: '',
        91: '',
        92: '',
        93: '',
        94: '',
        95: '',
        96: '',
        97: '',
        98: '',
        99: ''
      },

      toDo: null,
      process: null,
      motives: null,
      variablesDefinition: null,
      events: [],
      customerState: '',
      customerData: '',
      treatment: '',
      creationTimeZone: '',
      registerTimeZone: '',
      closingTimeZone: '',
      purchaseTimeZone: '',
      statusTimeZone: '',
      variablesData: [],
      checklist: [],
      attachmentTypes: [],
      mails: [],
      phones: [],
      motivesTermsLoaded: {
        motiveId1: 0,
        motiveId2: 0,
        motiveId3: 0,
        termId1: 0,
        termId2: 0,
        termId3: 0
      }
    }
  },
  actions: {
    setTicket (ticket) {
      this.acd = ticket.acd
      this.ani = ticket.ani
      this.bofCode = ticket.bofCode
      this.branchOfficeId = ticket.branchOfficeId
      this.callId = ticket.callId
      this.processId = ticket.campaignId
      this.caseId = ticket.caseId
      this.channelCode = ticket.channelCode
      this.contactId = ticket.contactId
      this.creationDate = ticket.creationDate
      this.creationTimeZone = ticket.creationTimeZone
      this.customerBranchOffice = ''
      this.dni = ticket.dni
      this.groupId = ticket.groupId
      this.invoice = ticket.invoice
      this.motiveGloss = ticket.motiveGloss
      this.motiveId1 = ticket.motiveId1
      this.motiveId2 = ticket.motiveId2
      this.motiveId3 = ticket.motiveId3
      this.motiveReference = ticket.motiveReference
      this.planId = ticket.planId
      this.productCode = ticket.productCode
      this.productReference = ticket.productReference
      this.purchaseTimeZone = ticket.purchaseTimeZone
      this.registerTimeZone = ticket.registerTimeZone
      this.resultDate = ticket.resultDate
      this.resultIndicator = ticket.resultIndicator
      this.serialId = ticket.serialId
      this.statusTimeZone = ticket.statusTimeZone
      this.termGloss = ticket.termGloss
      this.termId1 = ticket.termId1
      this.termId2 = ticket.termId2
      this.termId3 = ticket.termId3
      this.termReference = ticket.termReference
      this.ticketIndicator = ticket.ticketIndicator
      this.treatment = ticket.treatment
      this.userBranchOfficeId = ticket.userBranchOfficeId
      this.userId = ticket.userId
      this.mails = ticket.mails
      this.phones = ticket.phones

      this.toDo = ticket.toDo ?? this.toDo

      if (!ticket.addresses) {
        ticket.addresses = []
      }

      if (!ticket.mails) {
        ticket.mails = []
      }

      if (!ticket.phones) {
        ticket.phones = []
      }

      this.fixedsData = {
        17: ticket.callId,
        18: ticket.ani,
        19: ticket.productCode,
        20: ticket.productReference,
        33: ticket.customerDocType,
        34: ticket.personDocType,
        35: ticket.customerType,
        36: ticket.personType,
        37: ticket.priorityCode,
        39: ticket.customerId,
        41: ticket.customerName,
        42: ticket.personId,
        43: ticket.personName,
        44: ticket.personLastName1,
        45: ticket.personLastName2,
        46: this.getDefaultValue(ticket.addresses[0]?.address),
        47: this.getDefaultValue(ticket.addresses[0]?.neiborhood),
        48: this.getDefaultValue(ticket.addresses[0]?.city),
        49: this.getDefaultValue(ticket.addresses[0]?.zipCode),
        50: this.getDefaultValue(ticket.addresses[0]?.state),
        51: this.getDefaultValue(ticket.addresses[0]?.type),
        52: this.getDefaultValue(ticket.addresses[1]?.address),
        53: this.getDefaultValue(ticket.addresses[1]?.neiborhood),
        54: this.getDefaultValue(ticket.addresses[1]?.city),
        55: this.getDefaultValue(ticket.addresses[1]?.zipCode),
        56: this.getDefaultValue(ticket.addresses[1]?.state),
        57: this.getDefaultValue(ticket.addresses[1]?.type),
        58: this.getDefaultValue(ticket.addresses[2]?.address),
        59: this.getDefaultValue(ticket.addresses[2]?.neiborhood),
        60: this.getDefaultValue(ticket.addresses[2]?.city),
        61: this.getDefaultValue(ticket.addresses[2]?.zipCode),
        62: this.getDefaultValue(ticket.addresses[2]?.state),
        63: this.getDefaultValue(ticket.addresses[2]?.type),
        64: this.getDefaultValue(ticket.mails[0]),
        65: this.getDefaultValue(ticket.mails[1]),
        66: this.getDefaultValue(ticket.mails[2]),
        67: this.getDefaultValue(ticket.phones[0]?.number),
        68: this.getDefaultValue(ticket.phones[1]?.number),
        69: this.getDefaultValue(ticket.phones[2]?.number),
        70: this.getDefaultValue(ticket.phones[3]?.number),
        71: this.getDefaultValue(ticket.phones[4]?.number),
        72: this.getDefaultValue(ticket.phones[5]?.number),
        73: this.getDefaultValue(ticket.phones[6]?.number),
        74: this.getDefaultValue(ticket.phones[7]?.number),
        75: this.getDefaultValue(ticket.phones[8]?.number),
        76: this.getDefaultValue(ticket.phones[9]?.number),
        77: this.getDefaultValue(ticket.phones[10]?.number),
        78: ticket.typeCode,
        79: ticket.statusCode,

        81: ticket.amId,
        82: ticket.amName,
        83: ticket.amGroupId,
        84: ticket.amBranchOfficeCode,
        85: ticket.capexAmount,
        86: ticket.opexAmount,
        87: ticket.capexPercent,
        88: ticket.opexPercent,
        89: ticket.productAmount,
        90: ticket.unitAmount,
        91: ticket.sizeCode,
        92: ticket.closingDate,
        93: ticket.qClosing,
        94: ticket.purchaseDate,
        95: ticket.stageCode,
        96: ticket.probablePercentOfSale,
        97: ticket.saleStatus,
        98: ticket.saleDateStatus,
        99: ticket.lossMotiveCode
      }

      const variables = {}

      for (let index = 100; index < 200; index++) {
        variables[index.toString()] = ''
      }

      each(ticket.data, (value, key) => {
        variables[(parseInt(key) + 100).toString()] = value
      })

      this.variablesData = variables
    },
    getDefaultValue (value) {
      return value ?? ''
    }
  },
  getters: {
    isLoaded () {
      return this.process !== null && this.motives !== null && this.variablesData !== null
    },
    taskName () {
      return this.toDo?.taskId ? `(${this.toDo.taskId}) ${this.toDo.taskName}` : ''
    },
    toDoIndicator () {
      return this.toDo?.toDoIndicator !== 'N'
    },
    enabledTicket () {
      return this.toDo?.enabledIndicator !== 'N'
    },
    codCustomerType () {
      return this.fixedsData[35]
    },
    codCustomerId () {
      return this.fixedsData[33]
    },
    customerId () {
      return this.fixedsData[39]
    },
    ticket () {
      const variablesData = {}

      Object.keys(this.variablesData).forEach(key => {
        variablesData[(parseInt(key) - 100).toString()] = this.variablesData[key]?.toString() ?? ''
      })

      return {
        acd: this.acd,
        addresses: [
          {
            address: this.fixedsData[46],
            neiborhood: this.fixedsData[47],
            city: this.fixedsData[48],
            zipCode: this.fixedsData[49],
            state: this.fixedsData[50],
            type: this.fixedsData[51]
          },
          {
            address: this.fixedsData[52],
            neiborhood: this.fixedsData[53],
            city: this.fixedsData[54],
            zipCode: this.fixedsData[55],
            state: this.fixedsData[56],
            type: this.fixedsData[57]
          },
          {
            address: this.fixedsData[58],
            neiborhood: this.fixedsData[59],
            city: this.fixedsData[60],
            zipCode: this.fixedsData[61],
            state: this.fixedsData[62],
            type: this.fixedsData[63]
          }
        ],
        amBranchOfficeCode: this.fixedsData[84],
        amGroupId: this.fixedsData[83],
        amId: this.fixedsData[81],
        amName: this.fixedsData[82],
        ani: this.ani,
        bofCode: this.bofCode,
        branchOfficeId: this.branchOfficeId,
        callId: this.callId,
        campaignId: this.processId,
        capexAmount: this.fixedsData[85],
        capexPercent: this.fixedsData[87],
        caseId: this.caseId,
        channelCode: this.channelCode,
        closingDate: this.fixedsData[92],
        contactId: this.contactId,
        creationDate: this.creationDate,
        creationTimeZone: this.creationTimeZone,
        customerBranchOffice: this.customerBranchOffice,
        customerDocType: this.fixedsData[33],
        customerId: this.fixedsData[39],
        customerName: this.fixedsData[41],
        customerType: this.fixedsData[35],
        data: variablesData,
        dni: this.dni,
        groupId: this.groupId,
        invoice: this.invoice,
        lossMotiveCode: this.fixedsData[99],
        mails: [
          this.fixedsData[64],
          this.fixedsData[65],
          this.fixedsData[66]
        ],
        motiveGloss: this.motiveGloss,
        motiveId1: this.motiveId1,
        motiveId2: this.motiveId2,
        motiveId3: this.motiveId3,
        motiveReference: this.motiveReference,
        opexAmount: this.fixedsData[86],
        opexPercent: this.fixedsData[88],
        personDocType: this.fixedsData[34],
        personId: this.fixedsData[42],
        personLastName1: this.fixedsData[44],
        personLastName2: this.fixedsData[45],
        personName: this.fixedsData[43],
        personType: this.fixedsData[36],
        phones: [
          {
            type: 'commercialPhone1',
            number: this.fixedsData[67]
          },
          {
            type: 'commercialPhone2',
            number: this.fixedsData[68]
          },
          {
            type: 'commercialFax',
            number: this.fixedsData[69]
          },
          {
            type: 'personalPhone1',
            number: this.fixedsData[70]
          },
          {
            type: 'personalPhone2',
            number: this.fixedsData[71]
          },
          {
            type: 'personalFax',
            number: this.fixedsData[72]
          },
          {
            type: 'mobilePhone',
            number: this.fixedsData[73]
          },
          {
            type: 'assistantPhone',
            number: this.fixedsData[74]
          },
          {
            type: 'otherPhone',
            number: this.fixedsData[75]
          },
          {
            type: 'otherFax',
            number: this.fixedsData[76]
          },
          {
            type: 'beeper',
            number: this.fixedsData[77]
          }
        ],
        planId: this.planId,
        priorityCode: this.fixedsData[37],
        probablePercentOfSale: this.fixedsData[96],
        productAmount: this.fixedsData[89],
        productCode: this.productCode,
        productReference: this.productReference,
        purchaseDate: this.fixedsData[94],
        purchaseTimeZone: this.purchaseTimeZone,
        qClosing: this.fixedsData[93],
        registerTimeZone: this.registerTimeZone,
        resultDate: this.resultDate,
        resultIndicator: this.resultIndicator,
        saleDateStatus: this.fixedsData[98],
        saleStatus: this.fixedsData[97],
        serialId: this.serialId,
        sizeCode: this.fixedsData[91],
        stageCode: this.fixedsData[95],
        statusCode: this.fixedsData[79],
        statusTimeZone: this.statusTimeZone,
        termGloss: this.termGloss,
        termId1: this.termId1,
        termId2: this.termId2,
        termId3: this.termId3,
        termReference: this.termReference,
        ticketIndicator: this.ticketIndicator,
        treatment: this.treatment,
        typeCode: this.fixedsData[78],
        unitAmount: this.fixedsData[90],
        userBranchOfficeId: this.userBranchOfficeId,
        userId: this.userId
      }
    }
  }
})
