export default {
  title: 'Atajos',

  shortcuts: {
    ACCIO: 'ADACT',
    ADJUN: 'ADATT',
    GLOSA: 'ADNTI',
    INSIS: 'ADINT',
    MENSA: 'ADMET',
    CHECK: 'ADCHT'
  }
}
