export default {
  contact_title: 'Contacto',
  contact_selection: 'Selección contacto',
  contact_type: 'Tipo de Contacto',
  document_type: 'Tipo de Documento',
  contact_id: 'Identificador',
  placeholder_contact_id: 'Ingrese',
  contact_segment: 'Segmento',
  name: 'Nombre',
  placeholder_contact_name: 'Ingrese',
  results: 'Resultados',

  search: 'Buscar',
  cancel: 'Cancelar',
  select: 'Seleccionar',

  contact_not_found_alert: 'No se encontraron contactos con los filtros ingresados',
  opt_all: 'Todos',

  identifierFormat: {
    A: 'alphanumeric',
    N: 'numeric',
    R: 'rut'
  }
}
