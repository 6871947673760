export default {
  title: 'Cambio de Proceso',
  subtitle: 'Seleccione Proceso',
  lbl_process: 'Proceso',
  lbl_motive1: 'Motivo 1',
  lbl_motive2: 'Motivo 2',
  lbl_motive3: 'Motivo 3',
  lbl_decision: 'Decisión',
  btn_ok: 'Aceptar',
  nothing: 'Ninguno',
  select: 'Seleccione',

  alert_select_decision: 'Debe seleccionar decisión para continuar',
  alert_message_dispatch: 'Ticket Nº <%= contactId %>-<%= serialId %> ha sido procesado con éxito.'
}
