const userFriendsIcon = 'fa-user-friends'

export default {
  sections: [
    {
      title: 'Operación',
      icon: 'fa-stream',
      isCurrentApp: true,
      item: [
        {
          type: 'ToDoFinder',
          menuType: 'SIMPLE',
          title: 'Por Hacer',
          icon: 'fa-inbox',
          permission: 'MNTDO',
          componentPath: '/',
          default: true
        },
        {
          type: 'HistoryFinder',
          menuType: 'SIMPLE',
          title: 'Historia',
          icon: 'fa-history',
          permission: 'MNHIS',
          componentPath: '/history'
        },
        {
          type: 'CreateHome',
          menuType: 'SIMPLE',
          title: 'Crear Gestión',
          icon: 'fa-plus-circle',
          permission: 'MNCRE',
          componentPath: '/create'
        },
        {
          type: 'CalendarHome',
          menuType: 'SIMPLE',
          title: 'Planificación',
          icon: 'fa-calendar-check',
          permission: 'MNPLV',
          componentPath: '/visitPlan'
        },
        {
          type: 'CustomerHome',
          menuType: 'SIMPLE',
          title: 'Clientes',
          icon: userFriendsIcon,
          permission: 'MNCLI',
          componentPath: '/customer'
        },
        {
          type: 'ArticleViewHome',
          menuType: 'SIMPLE',
          title: 'Base de Conocimiento',
          icon: 'fa-book-reader',
          permission: 'MNKDB',
          componentPath: '/knowledge'
        }
      ]
    },
    {
      title: 'Gestión',
      icon: 'fa-users-cog',
      item: [
        {
          type: 'Reports',
          menuType: 'PARENT',
          title: 'Reportes',
          icon: 'fa-chart-pie',

          options: [
            {
              type: 'ReportFinder',
              menuType: 'SIMPLE',
              title: 'Segmentador',
              icon: 'fa-table',
              permission: 'MNRPT',
              componentPath: '/management/report'
            },
            {
              type: 'ReportExportHome',
              menuType: 'SIMPLE',
              title: 'Exportaciones',
              icon: 'fa-file-export',
              permission: 'MNEXP',
              componentPath: '/management/reports/export'
            },
            {
              type: 'DashboardHome',
              menuType: 'SIMPLE',
              title: 'Reporte Metas',
              icon: 'fa-flag-checkered',
              permission: 'MNRGO',
              componentPath: '/management/reports/goal'
            }
          ]
        },
        {
          type: 'LoadHome',
          menuType: 'SIMPLE',
          title: 'Cargas Masivas',
          icon: 'fa-file-upload',
          permission: 'MNLOD',
          componentPath: '/management/loads'
        },
        {
          type: 'GoalHome',
          menuType: 'SIMPLE',
          title: 'Configurar Metas',
          icon: 'fa-calendar-check',
          permission: 'MNGOC',
          componentPath: '/management/visit-plan/goal'
        }
      ]
    },
    {
      title: 'Administración',
      icon: 'fa-tools',
      item: [
        {
          type: 'ParameterHome',
          menuType: 'SIMPLE',
          title: 'Parámetros',
          icon: 'fa-cogs',
          permission: 'MNPAR',
          componentPath: '/admin/parameter'
        },
        {
          type: 'BusinessRuleHome',
          menuType: 'SIMPLE',
          title: 'Reglas de Negocio',
          icon: 'fa-file-alt',
          permission: 'MNRUL',
          componentPath: '/admin/businessRule'
        },
        {
          type: 'CompanyHome',
          menuType: 'SIMPLE',
          title: 'Empresas',
          icon: 'fa-industry',
          permission: 'MNCOM',
          componentPath: '/admin/company'
        },
        {
          type: 'Security',
          menuType: 'PARENT',
          title: 'Seguridad',
          icon: 'fa-shield-alt',

          options: [
            {
              type: 'ApplicationHome',
              menuType: 'SIMPLE',
              title: 'Aplicaciones',
              icon: 'fa-window-restore',
              permission: 'MNAPL',
              componentPath: '/admin/application'
            },
            {
              type: 'ProfileHome',
              menuType: 'SIMPLE',
              title: 'Perfiles',
              icon: 'fa-users-cog',
              permission: 'MNPRF',
              componentPath: '/admin/profile'
            }
          ]
        },
        {
          type: 'User',
          menuType: 'PARENT',
          title: 'Organización',
          icon: 'fa-people-group',

          options: [
            {
              type: 'UserHome',
              menuType: 'SIMPLE',
              title: 'Usuarios',
              icon: 'fa-user',
              permission: 'MNUSR',
              componentPath: '/admin/user'
            },
            {
              type: 'GroupHome',
              menuType: 'SIMPLE',
              title: 'Grupos',
              icon: userFriendsIcon,
              permission: 'MNGRO',
              componentPath: '/admin/group'
            },
            {
              type: 'ReplaceHome',
              menuType: 'SIMPLE',
              title: 'Reemplazos',
              icon: 'fa-people-arrows-left-right',
              permission: 'MNREP',
              componentPath: '/admin/replace'
            }
          ]
        },
        {
          type: 'PlanHome',
          menuType: 'SIMPLE',
          title: 'Planes',
          icon: 'fa-chess',
          permission: 'MNPLN',
          componentPath: '/admin/plans'
        },
        {
          type: 'ProcessHome',
          menuType: 'SIMPLE',
          title: 'Procesos',
          icon: 'fa-hourglass-half',
          permission: 'MNPRO',
          componentPath: '/admin/process'
        },
        {
          type: 'DataListHome',
          menuType: 'SIMPLE',
          title: 'Listas',
          icon: 'fa-list',
          permission: 'MNLST',
          componentPath: '/admin/dataList'
        },
        {
          type: 'TemplateHome',
          menuType: 'SIMPLE',
          title: 'Plantillas',
          icon: 'fa-edit',
          permission: 'MNTMP',
          componentPath: '/admin/template'
        },
        {
          type: 'MessageHome',
          menuType: 'SIMPLE',
          title: 'Mensajes',
          icon: 'fa-envelope',
          permission: 'MNMSG',
          componentPath: '/admin/message'
        },
        {
          type: 'DocumentHome',
          menuType: 'SIMPLE',
          title: 'Documentos',
          icon: 'fa-solid fa-file-lines',
          permission: 'MNDOC',
          componentPath: '/admin/document'
        },
        {
          type: 'Interaction',
          menuType: 'PARENT',
          title: 'Interacciones',
          icon: 'fa-comments',

          options: [
            {
              type: 'InteractionSourceHome',
              menuType: 'SIMPLE',
              title: 'Orígenes',
              icon: 'fa-sign-in-alt',
              permission: 'MNITS',
              componentPath: '/admin/interaction/source'
            }
          ]
        },
        {
          type: 'Format',
          menuType: 'PARENT',
          title: 'Formatos',
          icon: 'fa-clipboard',

          options: [
            {
              type: 'AdminCustomerHome',
              menuType: 'SIMPLE',
              title: 'Tipos de clientes',
              icon: userFriendsIcon,
              permission: 'MNACS',
              componentPath: '/admin/customer'
            },
            {
              type: 'AdminContactHome',
              menuType: 'SIMPLE',
              title: 'Tipos de contactos',
              icon: 'fa-users',
              permission: 'MNACN',
              componentPath: '/admin/contact'
            },
            {
              type: 'AdminActionHome',
              menuType: 'SIMPLE',
              title: 'Tipos de acciones',
              icon: 'fa-tasks',
              permission: 'MNTAC',
              componentPath: '/admin/action'
            }
          ]
        },
        {
          type: 'SurveyHome',
          menuType: 'SIMPLE',
          title: 'Encuestas',
          icon: 'fa-poll-h',
          permission: 'MNSUD',
          componentPath: '/admin/survey'
        },
        {
          type: 'LayoutHome',
          menuType: 'SIMPLE',
          title: 'Layout',
          icon: 'fa-magic',
          permission: 'MNLAY',
          componentPath: '/admin/layout'
        },
        {
          type: 'FlowHome',
          menuType: 'PARENT',
          title: 'Flujos',
          icon: 'fa-random',

          options: [
            {
              type: 'FlowHome',
              menuType: 'SIMPLE',
              title: 'Flujos',
              icon: 'fa-random',
              permission: 'MNFLO',
              componentPath: '/admin/flow'
            },
            {
              type: 'RoleHome',
              menuType: 'SIMPLE',
              title: 'Roles',
              icon: 'fa-chalkboard-teacher',
              permission: 'MNROL',
              componentPath: '/admin/role'
            }
          ]
        },
        {
          type: 'Knowledge',
          menuType: 'PARENT',
          title: 'Base de Conocimiento',
          icon: 'fas fa-book-reader',

          options: [
            {
              type: 'ArticleHome',
              menuType: 'SIMPLE',
              title: 'Artículos',
              icon: 'fas fa-newspaper',
              permission: 'MNKAR',
              componentPath: '/admin/knowledge/article'
            },
            {
              type: 'CategoryHome',
              menuType: 'SIMPLE',
              title: 'Categorías',
              icon: 'fa-window-restore',
              permission: 'MNKCA',
              componentPath: '/admin/knowledge/category'
            },
            {
              type: 'TagHome',
              menuType: 'SIMPLE',
              title: 'Etiquetas',
              icon: 'fas fa-tags',
              permission: 'MNKTA',
              componentPath: '/admin/knowledge/tag'
            }
          ]
        },
        {
          type: 'ToolHome',
          menuType: 'SIMPLE',
          title: 'Herramientas',
          icon: 'fa-tools',
          permission: 'MNTOL',
          componentPath: '/admin/tool'
        },
        {
          type: 'PowerAppHome',
          menuType: 'SIMPLE',
          title: 'Power Apps',
          icon: 'fa-rocket',
          permission: 'MNPOW',
          componentPath: '/admin/tool'
        }
      ]
    }
  ],
  powerapp: {
    title: 'Power Apps',
    icon: 'fas fa-rocket'
  },
  process: {
    title: 'Procesos Activos'
  },
  notification: {
    title: 'Notificaciones',
    errors: 'Ultimos errores'
  },
  close: 'Cerrar',
  signout: 'Cerrar sesion',
  new_ticket: 'Crear Nuevo Ticket',
  image_placeholder: 'Imagen',
  tabs: {
    CustomerDetail: {
      icon: 'fa-user'
    },
    ContactDetail: {
      icon: 'fa-user'
    },
    ReportDetail: {
      icon: 'fa-chart-pie'
    },
    ActionDetail: {
      icon: 'fa-tasks'
    },
    InteractionDetail: {
      icon: 'fa-comments'
    }
  }
}
