export default {
  title: 'Planificación',
  subtitle: 'Planifique sus visitas a clientes para cumplir sus metas',

  my_customers: 'Mis Clientes',
  filters: 'Filtros',

  customer_filter: 'Filtrar cliente',

  frequency: {
    MES: {
      one: {
        one: '1 Visita por mes',
        many: '{qty} Visitas por mes'
      },
      many: {
        one: '1 Visita cada {fq} meses',
        many: '{qty} Visitas cada {fq} meses'
      }
    }
  },

  pending: {
    none: 'Meta completa',
    one: '1 Visita pendiente',
    many: '{qty} Visitas pendientes'
  },

  see_more: 'Ver más',
  see_less: 'Ver menos',

  lbl_badge_day: 'Día',
  lbl_badge_week: 'Semana',
  lbl_badge_month: 'Mes',
  lbl_badge_today: 'Hoy',

  calendar: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo'
  }
}
