import action from './action'
import article from './article'
import auth from './auth'
import avatar from './avatar'
import awk from './awk'
import calendar from './calendar'
import contact from './contact'
import create from './create'
import cropper from './cropper'
import customer from './customer'
import errors from './errors'
import header from './header'
import history from './history'
import input from './input'
import interaction from './interaction'
import login from './login'
import menubar from './menubar'
import notificationbar from './notificationbar'
import passwordRecovery from './passwordRecovery'
import shortcuts from './shortcuts'
import ticket from './ticket'
import timeline from './timeline'
import todo from './todo'
import visitPlan from './visitPlan'
import conversation from './conversation'

export default {
  action,
  article,
  auth,
  avatar,
  awk,
  calendar,
  contact,
  create,
  cropper,
  customer,
  errors,
  header,
  history,
  input,
  interaction,
  login,
  menubar,
  notificationbar,
  passwordRecovery,
  shortcuts,
  ticket,
  timeline,
  todo,
  visitPlan,
  conversation
}
