<template>
  <div
    ref="dropdown"
    class="autocomplete dropdown"
    :class="[isActive ? 'is-active' : '']"
  >
    <div
      v-if="multiple"
      class="dropdown-trigger is-multiple"
      @click.prevent.stop="toggleFinder"
    >
      <div
        v-for="(internalValueItem, key) in internalValues"
        :key="key"
        class="item"
      >
        <span class="tag is-info is-light">
          {{ internalValueItem | showName(items) }}
          <button
            class="delete is-small"
            @click.stop="onDeselect(internalValueItem)"
          />
        </span>
      </div>
    </div>
    <div
      v-else
      class="dropdown-trigger"
      @click.prevent.stop="toggleFinder"
    >
      <p
        class="control is-expanded has-icons-right"
        :class="{ 'has-icons-left': hasIcons }"
      >
        <input
          class="input"
          :class="[alert ? 'is-danger' : '', disabled ? 'is-cursor-not-allowed' : '']"
          :value="selectedLabel"
          :disabled="disabled"
          readonly
        >
        <span
          v-if="hasIcons"
          class="icon is-small is-left"
        >
          <i :class="selectedIcon" />
        </span>
        <span class="icon is-small is-right">
          <i class="fas fa-search" />
        </span>
      </p>
    </div>
    <div
      v-if="isActive"
      class="dropdown-menu"
    >
      <div class="dropdown-content-search">
        <input
          ref="searchInput"
          v-model="searchText"
          class="input"
          :placeholder="validPlaceholder"
          :disabled="disabled"
          @click.prevent.stop
        >
      </div>
      <div
        v-if="!multiple"
        class="dropdown-content"
      >
        <label
          v-for="(item, key) in availableItems"
          :key="key"
          class="dropdown-item"
          :class="[key === internalValue ? 'is-active': '']"
          @click.prevent.stop="selected(key)"
        >
          <span
            v-if="hasIcons"
            class="icon"
          >
            <i :class="item.icon" />
          </span>
          <span>{{ item.valueName }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { filter, findIndex, pickBy } from 'lodash'

export default {
  name: 'DataList',
  filters: {
    showName (key, namesMap) {
      return namesMap[key] ? namesMap[key] : key
    }
  },
  components: { },
  model: {
    events: 'input'
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      default: ''
    },
    listId: {
      type: String
    },
    condition: {
      type: [String, Object]
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasIcons: {
      type: Boolean,
      default: false
    },
    dependencyId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      internalValue: null,
      internalValues: [],
      searchText: '',
      isActive: false,
      items: [],
      backdrop: null,
      elementContainer: null
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].awk.list
    },
    selectedLabel () {
      if (this.items[this.internalValue]) {
        return this.items[this.internalValue].valueName
      }
      const splitted = this.value.split('|')
      return splitted[1]
    },
    selectedIcon () {
      if (this.items) {
        return this.items[this.internalValue].icon
      }
      return ''
    },
    availableItems () {
      let availableItems = []
      if (this.items) {
        availableItems = pickBy(this.items, item => item.valueName.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
      }
      return availableItems
    },
    validPlaceholder () {
      return this.placeholder ? this.placeholder : ''
    }
  },
  watch: {
    condition (newValue, oldValue) {
      if (newValue?.key === oldValue?.key || !oldValue?.key) {
        return
      }
      this.internalValue = null
      this.$emit('input', '|')
    },
    value () {
      this.processValue()
    },
    internalValue (value) {
      if (value && this.items[parseInt(value)]) {
        const currentItem = this.items[parseInt(value)]

        if (this.value?.key !== currentItem.value) {
          this.$emit('input', `${currentItem.value}|${currentItem.valueName}`)
        }
      }
    },
    internalValues () {
      this.$emit('input', this.internalValues)
    },
    isActive () {
      this.searchText = ''
      this.toggleDataList()
    }
  },
  created () {
    this.processValue()
  },
  mounted () {
    let currentElement = this.$refs.dropdown

    while (currentElement) {
      if (currentElement.localName === 'main' && currentElement.classList.contains('main')) {
        this.elementContainer = currentElement
        break
      }
      currentElement = currentElement.parentNode
    }
  },
  methods: {
    loadListData () {
      if (!this.listId || this.listId === '' || this.listId === '0') {
        this.$alert.warn(this.text.alert_invalid_list_id_warn)
        return
      }

      const loading = this.$loading.show()
      const http = this.$session.http('datalist')

      this.items = []
      this.flagEdit = false

      http({
        method: 'get',
        url: `/datalist/list/${this.listId}`,
        params: {
          condition: this.condition?.key ?? this.condition,
          dependencyId: this.dependencyId
        }
      })
        .then(response => {
          if (response.data.data) {
            this.items = response.data.data.listData
            this.processValue()
          }
        })
        .catch(this.$handle().handleErrorWith({
          404: {
            ignore: true
          }
        }))
        .finally(() => {
          this.$loading.remove(loading)
        })
    },
    processValue () {
      if (this.multiple) {
        this.internalValues = this.value
      } else {
        const splitted = this.value.split('|')
        const index = findIndex(this.items, item => {
          return item.value === (splitted[0])
        })
        if (index !== -1) {
          this.internalValue = index.toString()
        } else {
          this.internalValue = null
        }
      }
    },
    toggleFinder () {
      if (!this.disabled) {
        this.isActive = !this.isActive

        if (this.isActive) {
          this.loadListData()
          this.$nextTick(() => this.$refs.searchInput.focus())
          const callback = e => {
            this.isActive = false
            window.removeEventListener('click', callback)
          }
          window.addEventListener('click', callback)
        }
      }
    },
    selected (item) {
      this.isActive = false
      if (this.multiple) {
        const index = findIndex(this.internalValues, internal => {
          return internal === item
        })

        if (index === -1) {
          this.internalValues.push(item)
          this.internalValues.sort()
        } else {
          this.onDeselect(item)
        }
      } else {
        this.internalValue = item
      }
    },
    onDeselect (value) {
      if (this.multiple) {
        this.internalValues = filter(this.internalValues, val => {
          return val !== value
        })
        this.internalValues.sort()
      }
    },
    selectedItem (key) {
      const index = findIndex(this.internalValues, value => {
        return value === key
      })

      return index !== -1
    },
    toggleDataList () {
      if (this.isActive) {
        this.backdrop = document.createElement('div')
        this.backdrop.className = 'autocomplete-backdrop'
        this.backdrop.addEventListener('click', () => {
          this.isActive = false
        })
        document.body.appendChild(this.backdrop)
        this.$nextTick(() => this.centerElement(this.$refs.dropdown))
      } else if (this.backdrop) {
        document.body.removeChild(this.backdrop)
        this.backdrop = null
      }
    },
    centerElement (element) {
      const mainElement = this.elementContainer
      if (mainElement) {
        const mainRect = mainElement.getBoundingClientRect()
        const mainScrollTop = mainElement.scrollTop
        const elementRect = element.getBoundingClientRect()
        const elementOffsetTop = elementRect.top - mainRect.top + mainScrollTop
        const elementHeight = elementRect.height
        const mainHeight = mainElement.clientHeight

        const scrollY = elementOffsetTop - (mainHeight - elementHeight) / 2
        mainElement.scrollTo(0, scrollY)
      }
    }
  }
}
</script>
