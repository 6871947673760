import detail from './detail'
import category from './category'
import tag from './tag'
import finder from './finder'

export default {
  detail: detail,
  category: category,
  tag: tag,
  finder: finder,

  title: 'Base de Conocimiento',
  more_popular: 'Los Más Populares',
  new_contents: 'Temas Nuevos',
  read: 'de lectura',
  btn_view_more: 'Ver más',
  categories: 'Categorías',
  see_all_categories: 'Ver todas las categorías',
  come_back_later: 'Por favor, vuelve más tarde',
  tags: 'Etiquetas',
  see_all_tags: 'Ver todas las etiquetas',
  finder_title: 'Buscador',
  finder_placeholder: '¿En qué te podemos ayudar?'
}
