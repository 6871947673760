<template>
  <nav class="panel">
    <a
      v-if="list.length === 0"
      class="panel-block panel-group is-cursor-default"
    >
      {{ noResultFound }}
    </a>

    <ListItem
      v-for="item in list"
      :key="item.id"
      :item="item"
      :canDelete="canDelete"
      @delete="onDelete"
    />
  </nav>
</template>

<script>
export default {
  name: 'SidebarList',
  components: {
    ListItem: () => import('./ListItem')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    noResultFound: String,
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDelete (event) {
      this.$emit('delete', event)
    }
  }
}
</script>
