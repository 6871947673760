<template>
  <div class="control has-icons-right">
    <input
      v-model="input"
      class="input"
      :class="{'is-danger': alert}"
      :maxlength="maxLength"
      :disabled="disabled"
      :placeholder="placeholder"
      @paste="onPaste"
    >
    <a
      v-if="isEmpty"
      class="icon is-small is-right is-initial-pointer-events"
      :href="href"
      target="_blank"
    >
      <i :class="icon" />
    </a>
    <span
      v-else
      class="icon is-small is-right"
    >
      <i :class="icon" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  model: {
    events: 'input'
  },
  props: {
    value: {
      type: String
    },
    maxLength: {
      type: Number
    },
    alert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      input: ''
    }
  },
  computed: {
    placeholder () {
      return 'fas fa-exclamation-circle'
    },
    href () {
      return 'https://fontawesome.com/v6/search?o=r&m=free'
    },
    icon () {
      return this.input || 'fas fa-exclamation-circle'
    },
    isEmpty () {
      return this.input === ''
    }
  },
  watch: {
    value () {
      this.input = this.value
    },
    input () {
      this.$emit('input', this.input)
    }
  },
  created () {
    this.input = this.value
  },
  methods: {
    onPaste (event) {
      const pattern = '(<i class=")(?<icon>.*)("></i>)'
      const value = event.clipboardData.getData('Text')
      const match = value.match(pattern)
      if (match) {
        event.preventDefault()
        this.input = match.groups.icon
      }
    }
  }
}
</script>
