import attachment from './attachment'
import checklist from './checklist'
import comment from './comment'
import insistence from './insistence'
import message from './message'
import process from './process'
import ticket from './ticket'
import transfer from './transfer'

export default {
  attachment,
  checklist,
  comment,
  insistence,
  message,
  process,
  ticket,
  transfer
}
