export default {
  title: 'Mensaje',
  subtitle: 'Agregar mensaje',
  message_type: 'Mensaje',
  opt_select: 'Seleccione mensaje',
  variables: 'Datos personalizados',
  btn_preview: 'Previsualizar',
  title_preview: 'Previsualización',
  btn_send: 'Enviar',
  btn_add: 'Agregar destinatario',
  title_recipients: 'Destinatarios',
  title_message: 'Mensaje',
  lbl_recipient: 'Ingrese destinatario',
  recipient_type_alert: 'Se necesitan configurar tipos de destinatario para este mensaje',
  recipient_empty_list_alert: 'Debe agregar destinatarios para enviar el mensaje',
  types: {
    CANAL: 'Canal',
    MANUAL: 'Manual',
    RESPONSABLE: 'Responsable',
    USUARIO: 'Usuario',
    SUPERVISOR: 'Supervisor',
    JEFE: 'Jefe',
    CLIENTE: 'Cliente'
  },
  alert_must_save_variables_error: 'Para enviar un mensaje, los datos de contacto de los tipos de destinatario deben estar guardados.',
  invalid_email_alert: 'Ingrese un email válido',
  already_exists_recipient_alert: 'El destinatario ya existe',
  send_message: 'Mensaje enviado',
  not_found_message: 'No se encontraron mensajes'
}
