<template>
  <div
    ref="container"
    class="sidebar-float"
  >
    <div class="sidebar-float-backdrop" />

    <div
      class="sidebar-float-body"
      :class="[activeSidebar ? 'is-active' : '', classSidebar]"
    >
      <div class="sidebar-float-body-title">
        <div class="hero is-light">
          <div class="hero-body is-small">
            <h5 class="title is-5 ml-2">
              {{ title }}
              <span
                class="icon is-pulled-right"
                @click="onCancel"
              ><i class="fas fa-times" /></span>
              <div class="subtitle is-6 has-text-weight-semibold pt-1">
                {{ subtitle }}
              </div>
            </h5>
          </div>
        </div>
      </div>

      <div class="sidebar-float-body-items">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
const CLASS_HAS_SIDEBAR_FLOAT_CHILD = 'has-sidebar-float-child'
const CLASS_IS_SIDEBAR_FLOAT_ACTIVE = 'is-sidebar-float-active'

export default {
  name: 'SidebarFloat',
  props: [
    'classSidebar',
    'activeSidebar',
    'title',
    'subtitle'
  ],
  data () {
    return {
      isNChild: false,
      elementContainer: null
    }
  },
  mounted () {
    let currentElement = this.$refs.container

    while (currentElement) {
      if (currentElement.localName === 'div' && currentElement.classList.contains('sidebar-float-body')) {
        this.isNChild = true
        this.elementContainer = currentElement
        currentElement.classList.add(CLASS_HAS_SIDEBAR_FLOAT_CHILD)
        break
      } else if (currentElement.localName === 'main' && currentElement.classList.contains('main')) {
        this.elementContainer = currentElement
        currentElement.classList.add(CLASS_HAS_SIDEBAR_FLOAT_CHILD)
        break
      }
      currentElement = currentElement.parentNode
    }
    if (!this.isNChild) {
      // const mainElement = document.querySelector('main.main')
      const sidebarElement = document.querySelector('aside.sidebar')
      // mainElement.classList.add(CLASS_HAS_SIDEBAR_FLOAT_CHILD)
      if (sidebarElement) {
        sidebarElement.classList.add(CLASS_IS_SIDEBAR_FLOAT_ACTIVE)
      }
    }
  },
  beforeDestroy () {
    this.cleanClasses()
  },
  methods: {
    onCancel () {
      this.cleanClasses()
      this.$emit('cancel')
    },
    cleanClasses () {
      if (!this.isNChild) {
        // const mainElement = document.querySelector('main.main')
        const sidebarElement = document.querySelector('aside.sidebar')
        if (sidebarElement) {
          sidebarElement.classList.remove(CLASS_IS_SIDEBAR_FLOAT_ACTIVE)
        }
      }
      this.elementContainer.classList.remove(CLASS_HAS_SIDEBAR_FLOAT_CHILD)
    }
  }
}
</script>
