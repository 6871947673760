export default {
  title_filters: 'Clientes',
  subtitle_filters: 'Utilice los filtros para acotar su búsqueda',
  lbl_customer_type: 'Tipo de Cliente',
  lbl_document_type: 'Tipo de Identificador',
  lbl_customer_id: 'Identificador',
  lbl_customer_name: 'Nombre o Descriptor',
  lbl_status: 'Estado',
  lbl_select: 'Seleccione',
  lbl_all: 'Todos',
  btn_search: 'Buscar',
  btn_searching: 'Buscando',
  title_search: 'Búsqueda',
  subtitle_search: 'resultados encontrados para los filtros ingresados',
  new_customer: 'Nuevo cliente',
  new_customer_msg: 'O presione aquí para crear un nuevo cliente',
  first_not_found_title: 'Presiona buscar para realizar una búsqueda',
  first_not_found_subtitle: 'Intenta modificar o quitar los filtros',
  see: 'ver',
  customer_type_alert: 'El tipo de cliente es requerido para realizar la búsqueda',
  no_results_found_alert: 'No se encontraron registros con los filtros seleccionados',
  invalidrut_alert: 'El rut ingresado no es válido',
  document_type_alert: 'Si ingresa un identificador, debe seleccionar un tipo de identificador',
  records_per_page: 'Visualización por página',
  records: 'registros',
  records_options: {
    5: 5,
    10: 10,
    20: 20
  },
  identifierFormat: {
    A: 'alphanumeric',
    N: 'numeric',
    R: 'rut'
  },
  statusCode: {
    VIGEN: 'Activos',
    NOVIG: 'Inactivos'
  },
  all: 'Todos',
  none: 'Ninguno'
}
