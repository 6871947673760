import axios from 'axios'
import Vue from 'vue'
import Keycloak from 'keycloak-js'
import { createPinia, PiniaVuePlugin, mapState } from 'pinia'
import App from './App.vue'
import ErrorApp from './Error.vue'

import router from './router'
// import text from './text'

import './plugins'
import './directives'
import '@/components/awk'
import config from '@/config'

import moment from 'moment'
import numeral from 'numeral'

import 'numeral/locales/es'
import { useErrorStore } from './stores/error'
import { useTextStore } from './stores/text'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

const defaultLanguage = 'es'
moment.suppressDeprecationWarnings = true
moment.updateLocale('es', {
  weekdays: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ],
  monthsShort: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic'
  ]
})

moment.locale(defaultLanguage)
numeral.locale(defaultLanguage)

Vue.config.productionTip = false

const loading = Vue.loading.show()

let domain = 'auris'

const expression = /(.+)\.\w+\.\w+$/gm
const result = expression.exec(window.location.hostname)
if (result && result[1]) {
  domain = result[1]
}

let initApp = ErrorApp
let errorType = null
let requiredReload = false

let keycloak = null

const instance = Vue.session.http('instance')

axios.interceptors.response.use(
  response => response,
  error => {
    const errorStore = useErrorStore()
    errorStore.addError(error)
    return Promise.reject(error)
  }
)

instance
  .get('/instance')
  .then(response => {
    const config = response.data.data

    if (!config?.domain?.auth) {
      errorType = 'instance'
      requiredReload = true
      return Promise.reject(new Error('Invalid instance'))
    }

    return Promise.resolve(config.domain.auth)
  })
  .then(initOptions => {
    keycloak = new Keycloak(initOptions)

    return keycloak.init({ onLoad: initOptions.onLoad })
  })
  .then(auth => {
    if (!auth) {
      errorType = 'auth'
      requiredReload = true
      return Promise.reject(new Error('no auth'))
    }
    console.info('Authenticated', keycloak)

    Vue.session.keycloak = keycloak

    // Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.session.id(keycloak.tokenParsed.session_state)
            Vue.session.apiKey(keycloak.token)
          }
        })
        .catch((e) => {
          console.error('Failed to refresh token', e)
          Vue.alert.error('Session expire')
          setTimeout(() => { window.location.reload() }, 5000)
        })
    }, 6000)

    return axios({
      method: 'post',
      url: `${config.session.endpoint}/session`,
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      }
    })
  })
  .then(response => {
    const session = response.data.data.session
    session.permissions = response.data.data.permissions

    Vue.session.id(keycloak.tokenParsed.session_state)
    Vue.session.apiKey(keycloak.token)
    Vue.session.save(session)

    initApp = App
  })
  .catch(e => {
    console.error('Authentication Failed:', e)
    if (!errorType) {
      if (e.response?.status === 403) {
        errorType = 'no-auth'
        requiredReload = false
      } else {
        errorType = 'session'
        requiredReload = true
      }
    }
  })
  .finally(() => {
    const app = new Vue({
      provide: {
        $alert: Vue.alert,
        $loading: Vue.loading,
        $responsive: Vue.responsive,
        $session: Vue.session,
        $tabs: Vue.tabs
      },
      data: {
        version: process.env.VUE_APP_VERSION,
        screenWidth: screen.width,
        keycloak,
        domain,
        errorType,
        requiredReload
      },
      computed: {
        ...mapState(useTextStore, ['language', 'globalText'])
      },
      watch: {
        language (value) {
          moment.locale(value)
          numeral.locale(value)
        }
      },
      created () {
        const textStore = useTextStore()
        textStore.language = defaultLanguage
        window.onresize = () => {
          this.screenWidth = screen.width
        }
        this.$loading.remove(loading)
      },
      render: h => h(initApp),
      router: errorType ? null : router,
      pinia
    })
    app.$mount('#app')
  })
