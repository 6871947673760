<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content">
      <div class="box">
        <div>
          {{ message }}
        </div>
        <div v-if="requiredReload">
          {{ text.refresh }} ({{ remainTime }})
        </div>
        <div>
          <button
            class="button"
            @click.prevent="refresh"
          >
            {{ text.refresh_now }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'noty/src/themes/bootstrap-v4.scss'
import '@/sass/app.scss'

export default {
  name: 'Error',
  components: { },
  data () {
    return {
      remainTime: 30
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].errors.login
    },
    type () {
      return this.$root.errorType ?? 'auth'
    },
    requiredReload () {
      return this.$root.requiredReload ?? false
    },
    message () {
      return this.text[this.type]
    }
  },
  mounted () {
    if (this.requiredReload) {
      setInterval(() => {
        if (this.remainTime === 0) {
          this.refresh()
        } else if (this.remainTime > 0) {
          this.remainTime--
        }
      }, 1000)
    }
  },
  methods: {
    refresh () {
      window.location.reload()
    }
  }
}
</script>
