<script setup>
import { computed, defineEmits, defineProps } from 'vue'

const props = defineProps({
  classButton: {
    type: Array,
    default: () => []
  },
  icon: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['click'])

const hasIcon = computed(() => {
  return Boolean(props.icon)
})

const hasLabel = computed(() => {
  return Boolean(props.label)
})

const onClick = () => {
  emit('click')
}
</script>

<template>
  <button
    type="button"
    class="button"
    :class="classButton"
    @click="onClick"
  >
    <span
      v-if="hasIcon"
      class="icon"
    >
      <i :class="icon" />
    </span>
    <span v-if="hasLabel">{{ label }}</span>
  </button>
</template>
