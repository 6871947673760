<template>
  <DatePicker
    v-model="internalValue"
    mode="date"
    :is-range="true"
    :update-on-input="true"
    :locale="options.lang"
    :minDate="options.minDate"
    :maxDate="options.maxDate"
  >
    <template #default="{ inputValue, inputEvents }">
      <div
        class="field has-addons"
      >
        <div class="control">
          <input
            class="input"
            :value="inputValue.start"
            :disabled="disabled"
            v-on="inputEvents.start"
          >
        </div>
        <div class="control">
          <a class="button is-static is-reverse">
            <span class="icon">
              <i class="fa fa-chevron-right" />
            </span>
          </a>
        </div>
        <div class="control">
          <input
            class="input"
            :value="inputValue.end"
            :disabled="disabled"
            v-on="inputEvents.end"
          >
        </div>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import moment from 'moment'
import { DatePicker } from 'v-calendar'

export default {
  name: 'DateRangePicker',
  components: {
    DatePicker
  },
  model: {
    events: 'input'
  },
  props: {
    value: {
      type: [Object, String],
      default: () => moment()
    },
    lang: {
      type: String,
      default: 'es'
    },
    minDate: {
      type: Object
    },
    maxDate: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      watchValue: false,
      options: {},
      internalValue: null
    }
  },
  watch: {
    value (newValue, oldValue) {
      const isSameStart = newValue.start.isSame(oldValue.start)
      const isSameEnd = newValue.end.isSame(oldValue.end)
      if (!isSameStart || !isSameEnd) {
        this.updateCalendar()
      }
    },
    internalValue (newValue, oldValue) {
      if (oldValue?.start.getTime() !== newValue?.start.getTime() || oldValue?.end.getTime() !== newValue?.end.getTime()) {
        const event = {
          start: moment(this.internalValue.start)
        }

        if (this.internalValue.end) {
          event.end = moment(this.internalValue.end)
        }

        this.$emit('input', event)
      }
    }
  },
  created () {
    const options = {
      lang: this.lang,
      minDate: this.minDate ? this.minDate.startOf('day').toDate() : null,
      maxDate: this.maxDate ? this.maxDate.endOf('day').toDate() : null
    }
    this.options = options
  },
  mounted () {
    this.updateCalendar()
  },
  methods: {
    updateCalendar () {
      let startDate = moment().startOf('day')
      let endDate = moment().endOf('day')

      if (this.value.start) {
        startDate = this.value.start.startOf('day').toDate()
      }
      if (this.value.end) {
        endDate = this.value.end.endOf('day').toDate()
      }

      this.internalValue = {
        start: startDate,
        end: endDate
      }
    }
  }
}
</script>
