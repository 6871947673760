export default {
  lbl_title_diary: 'Agenda',
  btn_export: 'Exportar',
  export_types: {
    excel: 'Archivo XLSX',
    csv: 'Archivo CSV'
  },
  btn_reports: 'Reportes',
  btn_set_month: 'Configurar metas',
  btn_to_plan: 'Planificar',
  btn_create_event: 'Crear acción',

  lbl_badge_day: 'Día',
  lbl_badge_week: 'Semana',
  lbl_badge_month: 'Mes',
  lbl_badge_today: 'Hoy',

  calendar: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo'
  },

  lbl_new_action: 'Nueva Acción'
}
