<script setup>
import { computed, defineEmits, defineProps, inject, onMounted, ref, watch } from 'vue'
import { useTextStore } from '@/stores/text'

import moment from 'moment'
import { DatePicker } from 'v-calendar'

const props = defineProps({
  modelValue: {
    type: [Object, String],
    default: () => moment()
  },
  lang: {
    type: String,
    default: 'es'
  },
  minDate: {
    type: Object
  },
  maxDate: {
    type: Object
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input:modelValue'])

const $alert = inject('$alert')

const textStore = useTextStore()

const text = computed(() => textStore.current.awk.calendarPicker)

const lang = ref('es')
const minDate = ref({})
const maxDate = ref({})
const internalValue = ref(null)

const attrs = ref([{
  key: 'today',
  highlight: {
    fillMode: 'outline'
  },
  dates: new Date()
}])

const updateCalendar = () => {
  if (props.modelValue) {
    const startDate = moment(props.modelValue).startOf('day').toDate()
    internalValue.value = startDate
  }
}

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    const isSameStart = moment(newValue).isSame(moment(oldValue))
    if (!isSameStart) {
      updateCalendar()
    }
  }
)

watch(
  internalValue,
  (newValue, oldValue) => {
    if (newValue && oldValue !== newValue) {
      const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (internalValue.value !== '' && !dateRegex.test(moment(newValue).format('DD/MM/YYYY'))) {
        internalValue.value = ''
        $alert.info(text.value.alert_format_date_invalid)
      }
    }
    emit('input:modelValue', moment(internalValue.value).isValid() ? moment(internalValue.value) : '')
  }
)

onMounted(() => {
  lang.value = props.lang
  minDate.value = props.minDate ? props.minDate.startOf('day').toDate() : null
  maxDate.value = props.maxDate ? props.maxDate.endOf('day').toDate() : null

  updateCalendar()
})

</script>

<template>
  <DatePicker
    v-model="internalValue"
    mode="date"
    :locale="lang"
    :minDate="minDate"
    :maxDate="maxDate"
    :attributes="attrs"
    :popover="{ visibility: 'focus' }"
  >
    <template #default="{ inputValue, inputEvents }">
      <div class="control has-icons-right">
        <input
          class="input"
          maxlength="10"
          :value="inputValue"
          :disabled="disabled"
          v-on="inputEvents"
        >
        <span class="icon is-small is-right">
          <i
            class="fa fa-calendar"
          />
        </span>
      </div>
    </template>
  </DatePicker>
</template>
