export default {
  new_ticket: 'Nueva gestión',
  about_ticket: 'Acerca de la Gestión',
  about_customer: 'Datos del Cliente',
  about_contact: 'Datos del Contacto',
  customer_title: 'Datos del Cliente',
  document_type: 'Documento',
  customer_document_id: 'Docto. Cliente',
  customer_id: 'ID Cliente',
  customer_name: 'Nombre Cliente',
  open_customer: 'Abrir Cliente',
  search_customer: 'Buscar Cliente',

  contact_title: 'Datos del Contacto',
  contact_document_id: 'Docto. Contacto',
  contact_id: 'ID Contacto',
  contact_name: 'Nombre Contacto',
  last_name1: 'Apellido Paterno',
  last_name2: 'Apellido Materno',
  search_contact: 'Buscar Contacto',
  open_contact: 'Abrir Contacto',

  motives_title: 'Clasificación',
  motive1: 'Motivo 1',
  motive2: 'Motivo 2',
  motive3: 'Motivo 3',

  alert_motive1: 'Debe seleccionar clasificación',

  reference: 'Asunto',
  reference_placeholder: 'Esta gestión no tiene asunto',
  description: 'Descripción',
  characters_remaining: ' caracteres restantes',

  reference_resolution: 'Referencia',
  description_resolution: 'Descripción',

  terms_title: 'Resolución',
  term1: 'Término 1',
  term2: 'Término 2',
  term3: 'Término 3',

  title_nothing: 'Ninguno',

  state_ticket: 'Estado <%= toDo.statusDescription %>',
  date_ticket: 'Creado el <%= currentDate %> <% if (endDate !== "") { %> hasta <%= endDate %> <% } %>',

  task: 'Tarea',
  start_task: 'Inicio tarea',
  term_task: 'Plazo tarea',

  originator_user: 'Originador',
  start_ticket: 'Inicio gestión',
  term_ticket: 'Plazo gestión',
  check_ticket: 'Revisado',

  role: 'Rol responsable',
  group: 'Grupo responsable',

  script: 'Guión',
  replicate: 'Replicar Gestión',
  save: 'Guardar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  dispatch_title: 'Despachar',

  info: {
    about_todo: 'Plazo pendiente',
    term_date: 'Fecha término',
    about_ticket: 'Plazo gestión',
    status: 'Estado',
    responsible: 'Responsable'
  },

  dispatch: {
    decision: 'Decisión para despacho',
    responsible: 'Responsable',
    compromiseDate: 'Compromiso',
    select: 'Seleccione',
    ok: 'Despachar',
    dispatch: 'Despachar',
    alert_responsible: 'Debe seleccionar responsable para decisión:',
    alert_compromise: 'Debe ingresar fecha de compromiso para decisión:',
    alert_decision: 'Debe seleccionar decisión',
    alert_validate_terms: 'Debe seleccionar término',
    alert_validate_resolution: 'Debe ingresar comentario de resolución',
    confirm_rule: '¿Desea continuar?',
    alert_ticket_process: 'Gestión en proceso de actualización. Reintente en unos minutos',
    alert_ticket_dispatch: 'La gestión ha sido despachada por otro usuario. Ábrala nuevamente',
    alert_validate_dispatch: 'Debe completar los campos obligatorios para continuar',
    alert_validate_attachment: 'Debe adjuntar documentos obligatorios, favor verificar en sección Atajos',
    alert_validate_attachment_new_ticket: 'Para realizar el despacho, debe guardar la gestión y adjuntar los documentos obligatorios',
    alert_validate_checklist: 'Debe chequear actividades obligatorias en la opción de checklist, favor verificar en sección Atajos'
  },

  title_data_required: 'Datos obligatorios sin completar',

  checked: {
    title: 'Seleccionar fecha',
    ok: 'Aceptar'
  },

  variable_title: 'Datos Variables',
  fixed_title: 'Datos Fijos',
  sales_title: 'Datos Venta',

  alert_cod_customer_type: 'Debe seleccionar tipo cliente',
  alert_cod_customer_id: 'Debe seleccionar identificador cliente',
  alert_customer_id: 'Debe ingresar RUT del cliente',
  not_found_customer: 'Cliente no encontrado',
  alert_cod_customer_type_error: 'Tipo de contacto seleccionado no disponible para el proceso',

  alert_cod_contact_type: 'Debe seleccionar tipo contacto',
  alert_cod_contact_id: 'Debe seleccionar identificador contacto',
  alert_contact_id: 'Debe ingresar RUT del contacto',
  alert_contact_id_selected: 'Debe seleccionar cliente antes de buscar contacto',
  not_found_contact: 'Cliente no encontrado',

  alert_ticket_not_loaded: 'Ha ocurrido un error obteniendo la gestión, por favor reintente',

  close_group: 'Cerrar <%= item.dataName %>',

  campaign_placeholder: 'Imagen proceso',

  copy: 'Copiar',
  alert_copy: 'Número de gestión copiado',

  insistence: {
    title: 'Seleccione gestión para agregar insistencia',
    create_ticket: 'Crear gestión',
    add_insistence: 'Agregar insistencia',
    cancel: 'Cancelar',
    insistence_on_time: 'Insistencia para gestión dentro de plazo de gestión',
    insistence_of_time: 'Insistencia para gestión fuera de plazo de gestión',
    create_insistence_success: 'La insistencia ha sido creada satisfactoriamente',

    description: 'Insistencia para gestión <span class="has-text-weight-bold"><%= ticket.contactId %>-<%= ticket.serialId %></span>, cliente <span class="has-text-weight-bold"><%= ticket.customerId %></span> <%= ticket.customerName %> <% if (ticket.motiveDescription1 !== "") { %>, con la tipificación <%= ticket.motiveDescription1 %> <% } %> <% if (ticket.motiveDescription2 !== "") { %> / <%= ticket.motiveDescription2 %> <% } %> <% if (ticket.motiveDescription3 !== "") { %> / <%= ticket.motiveDescription3 %> <% } %> con fecha de creación <span class="has-text-weight-bold"><%= creationDate %></span> y fecha de plazo <span class="has-text-weight-bold"><%= deadlineDate %></span>'
  },

  priority: {
    title: 'Prioridad',
    select: 'Seleccione',
    ok: 'Aceptar',
    no_priority: 'Sin prioridad',
    save_priority_success_alert: 'Prioridad actualizada con éxito',
    alert_save_priority_select_info: 'Seleccione una prioridad para continuar'
  },

  message_dispatch: 'Gestión Nº <%= contactId %>-<%= serialId %> ha sido procesado con éxito. \n',
  message_dispatch_date: 'Plazo gestión <%= docCreationDate %>',

  finish_script: 'Guión finalizado',
  script_not_found_alert: 'No se encontraron pasos configurados para el guión',
  results_not_found_alert: 'No se encontraron resultados configurados para el guión',

  step_description: 'Paso número: ',

  title_tools: 'Herramientas OnDemand',

  opt_default: 'Ninguno',

  // Calculo fechas dato variable
  calculated_days: '<%= calcDate %> <% if (calcDate === 1) { %> día <% } else { %> días <% } %>',
  calculated_months: '<%= calcDate %> <% if (calcDate === 1) { %> mes <% } else { %> meses <% } %>',
  calculated_years: '<%= calcDate %> <% if (calcDate === 1) { %> año <% } else { %> años <% } %>',

  good_morning: 'Buenos días',
  good_afternoon: 'Buenas tardes',
  good_night: 'Buenas noches',
  unnamed: 'Sin nombre'
}
