import detail from './detail'

export default {
  detail: detail,

  title: 'Categorías',
  btn_close: 'Cerrar',
  explore_categories: 'Explorar categorías',
  come_back_later: 'Por favor, vuelve más tarde'
}
