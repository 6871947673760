<script setup>
import { computed, defineEmits } from 'vue'

const emit = defineEmits(['close'])

const classClose = computed(() => {
  return ['is-danger']
})

const iconClose = computed(() => {
  return 'fa-solid fa-xmark'
})

const onClose = () => {
  emit('close')
}
</script>

<template>
  <BasicButton
    :classButton="classClose"
    :icon="iconClose"
    @click="onClose"
  />
</template>
