export default {
  title: 'Insistencia',
  subtitle: 'Agregar insistencia',
  placeholder: 'Haz click aquí para escribir un comentario...',
  characters_remaining: ' caracteres restantes',
  lbl_contact: 'Contacto',
  lbl_comment: 'Comentario',
  btn_save: 'Guardar',
  alert_create_insistence: 'Debe ingresar el contenido de la insistencia para continuar',
  alert_max_comment: 'El contenido supera el largo máximo permitido',
  alert_create_insistence_success: 'La insistencia ha sido creada satisfactoriamente'
}
