export default {
  title: 'Cambio de contraseña',
  subtitle: 'Ingrese una contraseña hasta que cumpla el criterio de aceptación.',
  conditions: 'Se determinará cuando su contraseña sea lo suficientemente segura para su uso.',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  retypePassword: 'Repita su nueva contraseña',
  access: 'Cambiar contraseña',
  info_password: 'Su contraseña no es lo suficientemente segura',
  password_match: 'Su contraseña es segura, puedes continuar',

  successUpdate: 'Contraseña actualizada exitosamente',
  invalidToken: 'Link de recuperacion inválido',
  logo_placeholder: 'Imagen logo'
}
