<template>
  <div
    class="control"
    :class="{ 'has-icons-right': hasIcon }"
  >
    <input
      ref="input"
      v-model="input"
      class="input"
      :class="classes"
      :minlength="minLength"
      :maxlength="maxLengthInternal"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="typeNative"
      @blur="onBlur"
      @focus="onFocus"
      @keypress="onKeyPress"
      @paste="onPaste"
      @keyup.enter="onEnterPress"
    >
    <span
      v-if="hasIcon"
      class="icon is-small is-right"
    >
      <i :class="icon" />
    </span>
  </div>
</template>

<script>
import template from 'lodash/template'
import trim from 'lodash/trim'
import numeral from 'numeral'

export default {
  name: 'Input',
  model: {
    events: 'input'
  },
  props: {
    value: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'text',
      validator (value) {
        return [
          'alpha',
          'alphanumeric',
          'calculation',
          'decimal',
          'email',
          'identifier',
          'integer',
          'money',
          'name',
          'numeric',
          'phone',
          'rut',
          'text',
          'ticket',
          'url',
          'username'
        ].indexOf(value) !== -1
      }
    },
    typeNative: {
      type: String,
      validator (value) {
        return ['search', 'text'].includes(value)
      }
    },
    formatted: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number
    },
    maxLength: {
      type: Number
    },
    customExpression: {
      type: String
    },
    idFormat: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '',
      validator (value) {
        return [
          '',
          'small',
          'normal',
          'medium',
          'large'
        ].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      input: '',
      internalValue: ''
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].input
    },
    expressions () {
      return this.text.expressions
    },
    icon () {
      return this.text.icons[this.type] || ''
    },
    maxLengthInternal () {
      switch (this.type) {
        case 'integer':
        case 'money':
        case 'numeric':
          return this.maxLength < 15 ? this.maxLength : 15
        case 'decimal':
          return this.maxLength < 17 ? this.maxLength : 17
      }
      return this.maxLength
    },
    expressionKey () {
      let expression = new RegExp()
      if (this.type === 'identifier' && this.idFormat && this.expressions.key[this.idFormat]) {
        expression = this.expressions.key[this.idFormat]
      } else if (this.type !== 'identifier' && this.expressions.key[this.type]) {
        expression = this.expressions.key[this.type]
      }
      return expression
    },
    expressionFull () {
      let expression = new RegExp()
      if (this.customExpression && trim(this.customExpression) !== '') {
        expression = new RegExp(this.customExpression)
      } else if (this.type === 'identifier' && this.idFormat && this.expressions.full[this.type] && this.expressions.full[this.type][this.idFormat]) {
        expression = this.expressions.full[this.type][this.idFormat]
      } else if (this.type === 'identifier' && this.idFormat && this.expressions.key[this.idFormat]) {
        expression = this.expressions.key[this.idFormat]
      } else if (this.type !== 'identifier' && this.expressions.full[this.type]) {
        expression = this.expressions.full[this.type]
      } else if (this.type !== 'identifier' && this.expressions.key[this.type]) {
        expression = this.expressions.key[this.type]
      }
      return expression
    },
    classes () {
      const classes = []
      if (this.alert) {
        classes.push('is-danger')
      }

      if (this.size !== '') {
        classes.push(`is-${this.size}`)
      }
      return classes
    }
  },
  watch: {
    value () {
      if (this.value !== undefined && this.value !== null) {
        if (this.value !== this.internalValue) {
          this.input = this.formatValue(this.value.toString())
        }
      } else {
        this.input = ''
      }
    },
    input () {
      if (this.type === 'identifier') {
        this.internalValue = this.cleanValue(this.input)
      } else if (this.type === 'decimal' || this.type === 'integer') {
        this.internalValue = numeral(this.input).value()
      } else {
        this.internalValue = this.input
      }
    },
    internalValue () {
      this.$emit('input', this.internalValue)
    },
    idFormat (newValue, oldValue) {
      if (this.idFormat) {
        const value = this.cleanValue(this.input, oldValue)
        if (this.validateFull(value)) {
          this.input = this.formatValue(value)
        } else {
          this.input = ''
        }
      }
    }
  },
  created () {
    if (this.value !== undefined) {
      let value = this.value.toString()
      if (typeof this.value === 'number' && this.type === 'decimal') {
        value = numeral(0).add(this.value).format('0.000')
      } else if (typeof this.value === 'string' && this.type === 'decimal' && /\d+\.\d+/.test(this.value)) {
        value = parseFloat(this.value)
        value = numeral(0).add(value).format('0.000')
      }
      this.input = this.formatted ? this.formatValue(value) : value
    } else {
      this.input = ''
    }
  },
  methods: {
    onKeyPress (event) {
      const caretStart = event.target.selectionStart
      const caretEnd = event.target.selectionEnd
      const input = this.input
      const value = [input.slice(0, caretStart), event.key, input.slice(caretEnd)].join('')
      if (!this.validateKey(value) && event.key !== 'Enter') {
        event.preventDefault()
      }
    },
    onPaste (event) {
      if (this.disabled) {
        return
      }

      event.preventDefault()
      const caretStart = event.target.selectionStart
      const caretEnd = event.target.selectionEnd
      const input = this.input
      let clipboard = this.cleanValue(event.clipboardData.getData('Text').trim())
      clipboard = clipboard.substring(0, this.maxLengthInternal)
      const value = [input.slice(0, caretStart), clipboard, input.slice(caretEnd)].join('')
      if (this.validateKey(value)) {
        this.input = value
      }
    },
    onBlur (event) {
      let value = this.input

      if (value !== '' && this.formatted) {
        if (this.validateFull(value)) {
          if (this.type === 'text') {
            value = this.input.substring(0, this.maxLengthInternal)
          } else if (this.type === 'decimal' || this.type === 'integer') {
            value = this.input.replace('.', '').substring(0, this.maxLengthInternal)
          }
          this.input = this.formatValue(value)
        } else {
          this.input = ''
        }
      }
      this.$emit('blur')
    },
    onFocus (event) {
      this.input = this.cleanValue(this.input)
    },
    onEnterPress () {
      if (this.formatted) {
        if (this.validateFull(this.input)) {
          this.$emit('enter')
        }
      } else {
        this.$emit('enter')
      }
    },
    validateKey (value) {
      if (this.validateCustom(value, 'key') && this.expressionKey.test(value)) {
        return true
      }
      return false
    },
    validateFull (value) {
      const type = this.idFormat ? this.idFormat : this.type
      if (this.validateCustom(value, 'full') && this.expressionFull.test(value)) {
        return true
      }
      if (this.input !== '') {
        if (type === 'rut') {
          this.$alert.info(this.text.invalidrut_alert)
        } else {
          this.$alert.info(this.text.invalidinput_alert)
        }
      }
      return false
    },
    validateCustom (value, mode) {
      const type = this.idFormat ? this.idFormat : this.type
      let result = true
      if (type === 'rut') {
        result = this.validateRut(value, mode)
      } else if (this.type === 'decimal') {
        result = this.validateDecimal(value, mode)
      }
      return result
    },
    cleanValue (value, oldValue) {
      const alternativeType = this.idFormat ? this.idFormat : this.type
      const type = oldValue !== undefined ? oldValue : alternativeType
      switch (type) {
        case 'identifier':
          return value.replace(/\./g, '').replace('-', '')
        case 'rut':
          return value.replace(/\./g, '').replace('-', '')
        case 'integer':
          return value.replace(/\./g, '')
        // case 'decimal':
        //   return value.replace(/\./g, '')
        case 'money':
          return value.replace(/\./g, '')
      }
      return value
    },
    validateRut (value, mode) {
      if (value.length > 0 && mode === 'full') {
        let M = 0
        let S = 1
        let rut = value.substring(0, value.length - 1)
        const dv = value.substring(value.length - 1, value.length)

        for (; rut; rut = Math.floor(rut / 10)) {
          S = (S + rut % 10 * (9 - M % 6)) % 11
          M++
        }
        const d = S ? S - 1 : 'K'

        if (d.toString() !== dv.toString().toUpperCase()) {
          return false
        }
      }
      return true
    },
    validateDecimal (value, mode) {
      if (mode === 'key') {
        const maxInteger = this.maxLengthInternal - 3
        const hasComma = value.indexOf(',') !== -1
        const integerLength = hasComma ? value.split(',')[0].length : value.length
        if (integerLength > maxInteger) {
          return false
        }
      }
      return true
    },
    formatValue (value) {
      if (value) {
        if (this.type === 'integer' || this.type === 'money') {
          return this.formatNumber(value)
        } else if (this.type === 'decimal') {
          return this.formatDecimal(value)
        }

        let expression = new RegExp()
        let templateStr = ''
        if (this.idFormat && this.expressions.template[this.type] && this.expressions.template[this.type][this.idFormat]) {
          expression = this.expressions.full[this.type][this.idFormat]
          templateStr = this.expressions.template[this.type][this.idFormat]
        } else if (this.type !== 'identifier' && this.expressions.template[this.type]) {
          expression = this.expressions.full[this.type]
          templateStr = this.expressions.template[this.type]
        }
        const exec = expression.exec(value.toString())
        const compiled = template(templateStr)

        if (exec && expression && templateStr) {
          return compiled({ value: exec })
        }
      }
      return value
    },
    formatNumber (value) {
      if (value) {
        return numeral(value).format('0,0')
      }
      return ''
    },
    formatDecimal (value) {
      if (value) {
        if (typeof value === 'string' && this.type === 'decimal' && /\d+\.\d+/.test(value)) {
          let tmpValue = parseFloat(value)
          tmpValue = numeral(0).add(tmpValue).format('0,0.00')
          return tmpValue
        } else {
          return numeral(value).format('0,0.00')
        }
      }
      return ''
    }
  }
}
</script>
