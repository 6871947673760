export default {
  title: 'Documentos',
  subtitle: 'Agregar documento',
  document_type: 'Tipo de documento',
  variables: 'Datos personalizados',
  btn_preview: 'Previsualizar',
  title_preview: 'Previsualización',
  btn_save: 'Guardar',
  btn_add: 'Agregar destinatario',
  opt_select: 'Seleccione',
  title_recipients: 'Destinatarios',
  title_document: 'Documento',
  lbl_recipient: 'Ingrese destinatario',
  recipient_type_alert: 'Se necesitan configurar tipos de destinatario para este documento',
  recipient_empty_list_alert: 'Debe agregar destinatarios para enviar el documento',
  types: {
    CANAL: 'Canal',
    MANUAL: 'Manual',
    RESPONSABLE: 'Responsable',
    USUARIO: 'Usuario',
    SUPERVISOR: 'Supervisor',
    JEFE: 'Jefe',
    CLIENTE: 'Cliente'
  },
  invalid_email_alert: 'Ingrese un email válido',
  already_exists_recipient_alert: 'El destinatario ya existe',
  alert_creation_document_success: 'Documento creado exitosamente',
  not_found_document_type: 'No se encontraron tipos de documentos'
}
