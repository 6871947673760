<template>
  <DatePicker
    v-model="internalValue"
    :mode="options.type"
    :is-range="isRange"
    :minute-increment="options.minuteSteps"
    :update-on-input="true"
    :masks="{ input: options.dateFormat }"
    :locale="options.lang"
    :minDate="options.minDate"
    :maxDate="options.maxDate"
    is24hr
  >
    <template #default="{ inputValue, inputEvents }">
      <div
        v-if="isRange"
        class="field has-addons"
      >
        <div class="control">
          <input
            class="input"
            :class="classes"
            :value="inputValue.start"
            :disabled="disabled"
            v-on="inputEvents.start"
          >
        </div>
        <div class="control">
          <a class="button is-static is-reverse">
            <span class="icon">
              <i class="fa fa-chevron-right" />
            </span>
          </a>
        </div>
        <div class="control">
          <input
            class="input"
            :value="inputValue.end"
            :disabled="disabled"
            v-on="inputEvents.end"
          >
        </div>
      </div>
      <div
        v-else
        class="control has-icons-right"
      >
        <input
          class="input"
          :class="classes"
          :value="inputValue"
          :disabled="disabled"
          v-on="inputEvents"
        >
        <span class="icon is-small is-right">
          <i
            class="fa"
            :class="[type === 'time' ? 'fa-clock' : 'fa-calendar']"
          />
        </span>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import { ceil } from 'lodash'
import moment from 'moment'
import { DatePicker } from 'v-calendar'

export default {
  name: 'Calendar',
  components: {
    DatePicker
  },
  model: {
    events: 'input'
  },
  props: {
    // Model prop
    value: {
      type: [Object, String],
      default: () => moment()
    },
    // Bulma calendar props
    type: {
      type: String,
      default: 'datetime',
      validator (value) {
        return ['date', 'time', 'datetime'].indexOf(value) !== -1
      }
    },
    color: {
      type: String,
      default: 'info',
      validator (value) {
        return ['black', 'dark', 'primary', 'link', 'info', 'success', 'warning', 'danger', 'grey'].indexOf(value) !== -1
      }
    },
    isRange: {
      type: Boolean,
      default: false
    },
    allowSameDayRange: {
      type: Boolean,
      default: true
    },
    lang: {
      type: String,
      default: 'es'
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    headerPosition: {
      type: String,
      default: 'top',
      validator (value) {
        return ['top', 'bottom'].indexOf(value) !== -1
      }
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showTodayButton: {
      type: Boolean,
      default: true
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    cancelLabel: {
      type: String
    },
    clearLabel: {
      type: String
    },
    todayLabel: {
      type: String
    },
    nowLabel: {
      type: String
    },
    validateLabel: {
      type: String
    },
    enableMonthSwitch: {
      type: Boolean,
      default: true
    },
    enableYearSwitch: {
      type: Boolean,
      default: true
    },
    minDate: {
      type: Object
    },
    maxDate: {
      type: Object
    },
    disabledDates: {
      type: Array,
      default: () => []
    },
    disabledWeekDays: {
      type: Array,
      default: () => []
    },
    weekStart: {
      type: Number,
      default: 1,
      validator (value) {
        return value >= 0 && value < 7
      }
    },
    minuteSteps: {
      type: Number,
      default: 15,
      validator (value) {
        return [1, 5, 10, 15, 30].indexOf(value) !== -1
      }
    },
    labelFrom: {
      type: String
    },
    labelTo: {
      type: String
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    // Calendar props
    hasStartTime: {
      type: Boolean,
      default: false
    },
    hasEndTime: {
      type: Boolean,
      default: false
    },
    minTime: {
      type: Object
    },
    maxTime: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      watchValue: false,
      options: {},
      internalValue: null
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].calendar
    },
    defaultEndDate () {
      const date = this.isRange ? moment() : null
      return date ? moment(date).endOf('day').toDate() : null
    },
    classes () {
      const classes = []
      if (this.alert) {
        classes.push('is-danger')
      }

      return classes
    }
  },
  watch: {
    value () {
      if (this.watchValue) {
        this.updateCalendar()
      }
    },
    internalValue () {
      this.watchValue = false
      this.$emit('input', this.selectDateTime())
      this.$nextTick(() => (this.watchValue = true))
    }
  },
  created () {
    const endOfDay = this.type === 'date'
    const startDate = this.isRange ? this.value.start : this.value
    const actualYear = moment().format('YYYY')

    const options = {
      type: this.type === 'datetime' ? 'dateTime' : this.type,
      color: this.color,
      isRange: this.isRange,
      allowSameDayRange: this.allowSameDayRange,
      lang: this.lang,
      dateFormat: this.dateFormat,
      showHeader: this.showHeader,
      headerPosition: this.headerPosition,
      showFooter: this.showFooter,
      showTodayButton: this.showTodayButton,
      showClearButton: this.showClearButton,
      cancelLabel: this.cancelLabel || this.text.label_cancel,
      clearLabel: this.clearLabel || this.text.label_clear,
      todayLabel: this.todayLabel || this.text.label_today,
      nowLabel: this.nowLabel || this.text.label_now,
      validateLabel: this.validateLabel || this.text.label_validate,
      enableMonthSwitch: this.enableMonthSwitch,
      enableYearSwitch: this.enableYearSwitch,
      startDate: startDate ? this.toDate(startDate) : '',
      endDate: this.value.end ? this.toDate(this.value.end, endOfDay) : this.defaultEndDate,
      minDate: this.minDate ? this.toDate(this.minDate) : null,
      maxDate: this.maxDate ? this.toDate(this.maxDate, true) : null,
      disabledDates: this.disabledDates,
      disabledWeekDays: this.disabledWeekDays,
      weekStart: this.weekStart,
      minuteSteps: this.minuteSteps,
      labelFrom: (this.labelFrom || this.labelFrom === '') ? this.labelFrom : this.text.label_from,
      labelTo: (this.labelTo || this.labelTo === '') ? this.labelTo : this.text.label_to,
      closeOnOverlayClick: this.closeOnOverlayClick,
      closeOnSelect: this.closeOnSelect,
      toggleOnInputClick: false,
      displayYearsCount: parseInt(actualYear) - 1900
    }
    this.options = options
  },
  mounted () {
    this.updateCalendar()
  },
  methods: {
    toDate (date, endOfDay) {
      if (date && date._i !== '' && date.isValid()) {
        if (endOfDay) {
          return moment(date).endOf('day').toDate()
        }

        return date.toDate()
      }
      return ''
    },
    toTime (time) {
      let momentTime = time.clone()
      if (this.type === 'datetime') {
        const add = ceil((time.minutes() / this.minuteSteps))
        momentTime = time.startOf('hour').add(add * this.minuteSteps, 'minutes')
      }
      return moment(momentTime.format('HH:mm'), 'HH:mm').toDate()
    },
    selectDateTime () {
      let event = {}

      if (this.isRange) {
        event.start = moment(this.internalValue.start)
        if (this.internalValue.end) {
          event.end = moment(this.internalValue.end).endOf('day')
        } else {
          if (moment(this.internalValue.start).isAfter(this.internalValue.end)) {
            event.end = moment(this.internalValue.start).endOf('day')
            event.start = moment(this.defaultEndDate)
          } else {
            event.end = moment(this.internalValue.end).endOf('day')
          }
        }
      } else {
        event = moment(this.internalValue)
      }

      return event
    },
    updateCalendar () {
      this.watchValue = false

      if (this.options.isRange) {
        let startDate = moment()
        let endDate = moment()

        if (this.value.start) {
          startDate = this.value.start.toDate()
        }
        if (this.value.end) {
          endDate = this.value.end.toDate()
        }

        this.internalValue = {
          start: startDate,
          end: endDate
        }
      } else {
        const date = moment(this.value)
        if (date.isValid()) {
          if (this.options.type === 'time') {
            this.internalValue = this.toTime(date)
          } else {
            this.internalValue = date.toDate()
          }
        }
      }

      this.$nextTick(() => (this.watchValue = true))
    }
  }
}
</script>
