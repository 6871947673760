export default {
  new_customer: 'Nuevo Cliente',
  avatar: 'Avatar',
  file: 'Archivo',
  close: 'Cerrar',
  save: 'Guardar',
  lbl_customer_type: 'Tipo de Cliente',
  lbl_document_type: 'Tipo de Documento',
  lbl_customer_id: 'Identificador',
  lbl_customer_name: 'Nombre o Descriptor',
  btn_search: 'Buscar',
  btn_searching: 'Buscando',
  title_search: 'Búsqueda',
  subtitle_search: 'resultados encontrados para los filtros ingresados'
}
