export default {
  title: 'Análisis de Cumplimiento',
  subtitle: 'Controla la gestion y el cumplimiento',

  filters: {
    period: 'Periodo',
    customerType: 'Tipo cliente',
    segment: 'Segmento',
    user: 'Usuario',
    defaultOption: 'Todos'
  },

  btn_export: 'Exportar',
  export_types: {
    excel: 'Archivo XLSX',
    csv: 'Archivo CSV'
  },

  table: {
    user: 'Usuario',
    customer: 'Cliente',
    goal_name: 'Nombre Meta',
    specific_customer: 'Cliente Específico',
    customer_segment: 'Segmento Cliente',
    contact_segment: 'Segmento Contacto',
    period: 'Periodo Meta',
    goal: 'Cantidad Meta',
    doneQty: 'Cantidad Terminadas',
    pendingQty: 'Cantidad Pendientes',
    cancelQty: 'Cantidad Canceladas'
  },

  summary: {
    segments: 'Segmentos',
    done: 'Terminadas',
    pending: 'Pendientes',
    cancel: 'Canceladas'
  },

  chart: {
    title: 'Visitas por Segmento',
    all: 'Todos',
    my_actions: 'Mis acciones',

    labels: [
      'Pendiente',
      'Cancelado',
      'Terminado'
    ]
  },

  flags: {
    S: 'Sí',
    N: 'No'
  }
}
