export default {
  hide_menu: 'Ocultar barra lateral',
  location: 'Usted está en:',
  searchbox: 'Buscar en Auris (gestiones, clientes u otros)',
  logout: 'Cerrar sesión',
  notifications: {
    title: 'NOTIFICACIONES'
  },
  logo_placeholder: 'Imagen logo',
  avatar_placeholder: 'Imagen avatar'
}
