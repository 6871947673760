export default {
  name: 'Chat',
  placeholder_search: 'Busca palabras o frases',
  copy: 'Copiar',
  alert_copy: 'Número de Id de la conversación copiado',
  not_found_title: 'No se encontraron resultados',
  not_found_conversation: 'Conversación no encontrada',
  not_found_messages: 'Mensajes de la conversación no encontrados',
  lbl_previous_messages: 'existen mensajes anteriores',
  lbl_messages_found: 'Mensajes encontrados con la busqueda',
  lbl_comment: 'Comentario',
  lbl_id: 'ID',
  seeMore: 'ver mas+',
  lbl_error_html: 'Tu navegador no admite la reproducción de videos HTML5.'
}
