import filters from './filters'
import sidebar from './sidebar'

export default {
  filters,
  sidebar,

  title_customer_info: 'Datos del Cliente',
  id: 'RUT',
  direction: 'Dirección',
  postal_code: 'Código Postal',
  id_super_parent: 'Rut Holding',
  business_name_super_parent: 'Razón Social Holding',
  title_contactability_info: 'Informacion de Contactabilidad',
  information: {
    title: 'Información'
  }
}
