<template>
  <div class="columns">
    <div class="column is-12">
      <AutoComplete
        v-model="optionsSelected"
        :items="optionsMap"
        :multiple="multiple"
      />
    </div>
  </div>
</template>

<script>

import { each } from 'lodash'

export default {
  name: 'MultipleSelect',
  components: {
    AutoComplete: () => import('@/components/awk/AutoComplete')
  },
  props: ['data', 'options'],

  data () {
    return {
      multiple: true,
      optionsSelected: []
    }
  },
  computed: {
    optionsMap () {
      const optionsMap = {}
      each(this.options, option => {
        optionsMap[option.value] = option.valueName
      })
      return optionsMap
    }
  },
  watch: {
    optionsSelected () {
      this.changeOptions()
    }
  },
  created () {
    if (this.data && this.data.value) {
      this.optionsSelected = this.data.value
    }
  },
  methods: {
    changeOptions () {
      this.$emit('change', {
        item: {
          // type: this.data.dataType,
          value: this.optionsSelected
        }
      })
    }
  }
}

</script>
