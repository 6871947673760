const toolBusData = {}
const instanceDomain = {}
const instanceIframe = {}
let isInitialized = false

const toolBus = {
  setInstance (instanceId, domain) {
    instanceDomain[instanceId] = domain
  },
  setIframe (instanceId, iframe) {
    instanceIframe[instanceId] = iframe
  },
  removeInstance (instanceId) {
    if (instanceDomain[instanceId]) {
      delete instanceDomain[instanceId]
    }
    if (instanceIframe[instanceId]) {
      delete instanceIframe[instanceId]
    }
  },
  on (instanceId, eventType, callback) {
    if (!toolBusData[instanceId]) {
      toolBusData[instanceId] = {}
    }

    toolBusData[instanceId][eventType] = callback
  },
  off (instanceId, eventType) {
    if (toolBusData[instanceId]) {
      if (!eventType) {
        delete toolBusData[instanceId]
      } else {
        delete toolBusData[instanceId][eventType]
      }
    }
  },
  emit (instanceId, eventType, eventData) {
    if (toolBusData[instanceId]) {
      if (toolBusData[instanceId][eventType]) {
        toolBusData[instanceId][eventType](eventData)
      }
    }
  }
}
const receiveMessage = (event) => {
  if (!event.data) {
    return
  }
  if (!event.data.instanceId) {
    return
  }
  if (!event.data.type) {
    console.warn('[ToolBus] Invalid message: No event type')
    return
  }
  if (!instanceDomain[event.data.instanceId]) {
    console.warn('[ToolBus] Domain not set')
    return
  }
  if ((event.origin === 'null' && event.source !== instanceIframe[event.data.instanceId].contentWindow) || (event.origin !== 'null' && event.origin !== instanceDomain[event.data.instanceId])) {
    console.error(`[ToolBus] Invalid origin domain [${event.origin}]`)
    return
  }

  toolBus.emit(event.data.instanceId, event.data.type, event.data.data)
}

if (!isInitialized) {
  isInitialized = true
  window.addEventListener('message', receiveMessage, false)
  console.log('[ToolBus] Initialized')
}

export default toolBus
