export default {
  state: 'Estado',
  pending: 'Pendiente',
  finished: 'Terminada',
  canceled: 'Cancelada',
  delete_action: 'Eliminar',

  save_action_state_alert: '¿Está seguro que desea guardar la acción con el estado seleccionado? El cambio es irreversible.',
  btn_confirm_label: 'Si',
  btn_cancel_label: 'No'
}
