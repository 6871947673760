import { each } from 'lodash'

const directiveMethod = (el, binding, vnode) => {
  let hasPermission = false
  if (binding.arg) {
    hasPermission = vnode.context.$session.hasPermission(binding.arg)
  } else if (binding.value) {
    hasPermission = evaluateComplexExpression(binding.value, vnode)
  }
  if (!hasPermission) {
    replaceElementWithComment(el, vnode)
  }
}

const evaluateComplexExpression = (value, vnode) => {
  let hasPermission = false
  const args = value.split(' ')
  let flagOr = false
  let flagAnd = false
  each(args, elm => {
    switch (elm) {
      case 'or':
        flagOr = true
        flagAnd = false
        break

      case 'and':
        flagAnd = true
        flagOr = false
        break

      default:
        if (flagOr) {
          hasPermission = hasPermission || vnode.context.$session.hasPermission(elm)
        } else if (flagAnd) {
          hasPermission = hasPermission && vnode.context.$session.hasPermission(elm)
        } else {
          hasPermission = vnode.context.$session.hasPermission(elm)
        }
    }
  })

  return hasPermission
}

const replaceElementWithComment = function (el, vnode) {
  const comment = document.createComment(' ')
  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })
  vnode.elm = comment
  vnode.text = ' '
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

export default {
  install (Vue) {
    Vue.directive('permission', directiveMethod)
  }
}
