<script setup>
import { computed, defineEmits, defineProps, inject, ref, watch } from 'vue'
import { useTextStore } from '@/stores/text'

import moment from 'moment'

const props = defineProps({
  modelValue: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input:modelValue'])

const $alert = inject('$alert')

const textStore = useTextStore()

const internalValue = ref('')

const text = computed(() => textStore.current.awk.timeInput)

const onBlur = () => {
  if (internalValue.value === '') {
    return
  }

  const formatTime = /^([0-1]?\d|2[0-3])[0-5]\d$/
  if (formatTime.test(internalValue.value.replace(':', ''))) {
    emit('input:modelValue', moment(internalValue.value, 'HHmm').format('YYYY-MM-DDTHH:mm:ss'))
    internalValue.value = moment(internalValue.value, 'HHmm').format('HH:mm')
  } else {
    internalValue.value = ''
    $alert.info(text.value.alert_format_time_invalid)
  }
}

const onFocus = () => {
  internalValue.value = internalValue.value.replace(':', '')
}

const onKeyPress = (event) => {
  const regNumber = /\d$/
  const key = event.key
  if (!regNumber.test(key)) {
    event.preventDefault()
  }
}

watch(
  () => props.modelValue,
  () => {
    internalValue.value = moment(props.modelValue).format('HH:mm')
  }
)

</script>

<template>
  <div class="control has-icons-right">
    <input
      v-model="internalValue"
      class="input"
      maxlength="4"
      :disabled="props.disabled"
      :placeholder="text.placeholder_time"
      @blur="onBlur"
      @focus="onFocus"
      @keypress="onKeyPress"
    >
    <span class="icon is-small is-right">
      <i
        class="fa fa-clock"
      />
    </span>
  </div>
</template>
