<script setup>
import { defineEmits, defineProps, ref } from 'vue'

defineProps({
  headerTitle: {
    type: String,
    required: true
  },
  headerButtonVisible: {
    type: Boolean,
    default: false
  },
  headerButtonIcon: {
    type: String,
    required: false
  },
  headerButtonIconRequired: {
    type: Boolean,
    required: false
  },
  headerTitleIconRequired: {
    type: String,
    required: false
  }
})
const emit = defineEmits(['onHeaderButtonClick'])

const isExpanded = ref(true)

const onHeaderButtonClick = () => {
  emit('onHeaderButtonClick')
}
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}
</script>

<template>
  <div class="collaspsible-container px-2 py-2">
    <div class="collaspsible-container--header">
      <slot
        name="header"
        :headerTitle="headerTitle"
        :toggleExpand="toggleExpand"
        :isExpanded="isExpanded"
      >
        <div class="columns">
          <div class="column is-12">
            <div class="level is-flex">
              <div class="level-left">
                <h5 class="title is-5 has-text-weight-bold">
                  <a
                    class="has-text-grey-darker"
                    @click.prevent="toggleExpand"
                  >
                    <span class="icon">
                      <i
                        class="fa-solid has-text-grey"
                        :class="[isExpanded ? 'fa-caret-down' : 'fa-caret-right']"
                      />
                    </span>

                    {{ headerTitle }}

                    <span
                      v-if="headerButtonIconRequired"
                      class="icon has-text-danger"
                      :title="headerTitleIconRequired"
                    >
                      <i
                        class="fa-solid fa-circle-exclamation"
                      />
                    </span>
                  </a>
                </h5>
              </div>

              <div
                v-if="headerButtonVisible"
                class="level-right mt-0"
              >
                <span
                  class="icon is-cursor-pointer has-text-info"
                  @click="onHeaderButtonClick"
                >
                  <i :class="headerButtonIcon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div
      class="collaspsible-container--body"
      :class="[isExpanded ? 'is-expanded' : 'is-collapsed']"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="css">
  .collaspsible-container--body {
    transition: height 1s ease;
  }
  .collaspsible-container--body.is-expanded {
    height: auto;
  }
  .collaspsible-container--body.is-collapsed {
    height: 0;
    overflow: hidden;
  }
</style>
