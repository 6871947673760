export default {
  avatar: 'Avatar',
  ok: 'Aceptar',
  cancel: 'Cancelar',

  update_avatar_confirm_alert: '¿Está seguro que desea modificar el avatar?',

  // Avatar genérico
  avatar_placeholder: 'Imagen avatar',
  avatar_title: 'Ajustar imagen',
  avatar_subtitle: 'Ajuste el contenedor para obterner la imagen deseada',
  btn_save: 'Guardar',
  save_avatar_confirm_alert: '¿Está seguro que desea utilizar esta imagen?',
  confirm: {
    ok: 'Sí',
    cancel: 'No'
  },
  save_avatar_success_alert: 'Imagen guardada correctamente'
}
