import cdetail from './cdetail'
import detail from './detail'
import home from './home'
import newCustomer from './new-customer'
import search from './search'

export default {
  cdetail: cdetail,
  detail: detail,
  newCustomer: newCustomer,
  home: home,
  search: search
}
