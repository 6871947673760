export default {
  customer_title: 'Cliente',
  contact_title: 'Contacto',
  customer_selection: 'Selección cliente',
  customer_type: 'Tipo de Cliente',
  document_type: 'Tipo de Documento',
  customer_id: 'Identificador',
  placeholder_customer_id: 'Ingrese',
  name: 'Nombre',
  placeholder_customer_name: 'Ingrese',
  results: 'Resultados',

  search: 'Buscar',
  cancel: 'Cancelar',
  select: 'Seleccionar',
  add_customer: 'Seleccionar cliente',
  add_contact: 'Seleccionar contacto',

  customer_not_found_alert: 'No se encontraron clientes con los filtros ingresados',

  identifierFormat: {
    A: 'alphanumeric',
    N: 'numeric',
    R: 'rut'
  }
}
