export default {
  title: 'Bienvenido',
  subtitle: 'Para iniciar sesión por favor ingrese sus datos',
  subtitle_company: 'Selecciona tu empresa para continuar',
  subtitle_recovery: 'Ingresa tu nombre de usuario para recuperar tu contraseña',
  user: 'Usuario',
  password: 'Contraseña',
  company: 'Empresa',
  access: 'Iniciar sesión',
  passwordRecovery: '¿Problemas para acceder?',
  passwordRecoverySuccess: 'Se ha enviado un correo para recuperar su cuenta. <br> Si los problemas persisten, contacte al administrador Auris',
  passwordRecover: 'Recuperar contraseña',
  back: 'Volver',

  user_email_alert: 'Usuario no posee e-mail',
  alert_login: 'Usuario o contraseña incorrecta. Si no recuerda sus datos, presione botón ¿Problemas para acceder?',
  authentication_failed: 'Error de autenticación',

  invalid_instance: 'Instancia no valida',

  azure_ad: {
    login_btn: 'Accede con Microsoft'
  }
}
