<template>
  <div
    class="dropdown"
    :class="[isActive ? 'is-active' : '']"
  >
    <div class="dropdown-trigger">
      <div class="field">
        <p class="control is-expanded has-icons-right">
          <input
            ref="searchInput"
            v-model="searchText"
            class="input"
            :placeholder="placeholder"
            @click.prevent.stop="toggle"
          >
          <span class="icon is-small is-right"><i class="fas fa-search" /></span>
        </p>
      </div>
    </div>
    <div
      id="dropdown-menu"
      class="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          v-for="(value, key) in availableItems"
          :key="key"
          class="dropdown-item"
          @click.prevent.stop="selected(key, value)"
        >{{ value }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { pickBy } from 'lodash'

export default {
  name: 'DropdownSearch',
  components: { },
  props: ['items'],
  data () {
    return {
      isActive: false,
      searchText: '',
      placeholder: ''
    }
  },
  computed: {
    availableItems () {
      let availableItems = []
      if (this.items) {
        availableItems = pickBy(this.items, item => item.toUpperCase().indexOf(this.searchText.toUpperCase()) !== -1)
      }
      return availableItems
    }
  },
  methods: {
    toggle () {
      this.isActive = !this.isActive
      this.placeholder = this.searchText
      this.searchText = ''

      if (this.isActive) {
        const callback = e => {
          this.isActive = false
          this.searchText = this.placeholder
          window.removeEventListener('click', callback)
        }
        window.addEventListener('click', callback)
      }
    },
    selected (value, text) {
      this.isActive = false
      this.searchText = text
      this.placeholder = text
      this.$emit('selected', { item: value })
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown {
    width: 100%
  }

  .dropdown-content {
    overflow-y: scroll;
    max-height: 200px;
  }

  .dropdown-trigger {
    width: 100%
  }
  .dropdown-menu {
    width: 100%
  }

</style>
