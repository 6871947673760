const Loading = {
  show () {
    const loading = document.createElement('div')
    loading.className = 'loading'
    const preloader = document.createElement('div')
    preloader.className = 'preloader'
    loading.appendChild(preloader)
    document.body.appendChild(loading)
    return loading
  },
  remove (loading) {
    document.body.removeChild(loading)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$loading = Loading
    Vue.loading = Loading
  }
}
