export default {
  COMM: 'Error al contactar al servidor',
  PROCESS: 'Ha ocurrido un error en la acción realizada. Favor intentar nuevamente o contactar a mesa de ayuda si el problema persiste.',
  AUTH: 'Error de autenticación',

  login: {
    refresh: 'Este sitio refrescara automaticamente',
    refresh_now: 'Refrescar ahora',
    auth: 'Lo siento no ha sido posible autenticar tu cuenta, por favor reintenta.',
    session: 'Lo siento, ha ocurrido un error al crear tu sesion, por favor reintenta.',
    instance: 'Lo siento, ha ocurrido un error al obtener la configuración de tu instancia.',
    'no-auth': 'Lo siento, no tienes acceso, por favor comunicate con el administrador del sistema'
  },

  CAPTURE_MATCH: 'Error huellas no coinciden',
  QUESTION_MATCH: 'Respuesta no coincide',
  CAPTURE_GENERAL: 'Error al capturar huellas',
  REQUIRED_FIELD: 'Por Favor complete los campos en rojo',
  CLOSED: 'Cerrado por el usuario',
  WS_CONNECTION: 'No es posible contactar componente local',
  REQUIRED: 'Campo requerido',
  INVALID_FORMAT: 'Formato inválido'
}
