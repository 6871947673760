import Vue from 'vue'
import Router from 'vue-router'
import operationRoutes from './routes-operation'
import powerAppRoutes from './routes-powerapp'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ '@/components/HomeContainer'),
      beforeEnter: (to, from, next) => {
        Vue.session.validate((session, error) => {
          if (!error && session.DATA07 !== '0') {
            next()
          } else {
            console.log('error on router validate session', error)
            window.location.reload()
          }
        })
      },
      children: [
        ...operationRoutes,
        ...powerAppRoutes
      ]
    },
    {
      path: '/interaction/:source/:sourceId',
      name: 'interaction',
      component: () => import(/* webpackChunkName: "interaction" */ '@/components/interaction/InteractionLanding'),
      beforeEnter: (to, from, next) => {
        Vue.session.validate((session, error) => {
          if (!error && session.DATA07 !== '0') {
            next()
          } else {
            console.log('error on router validate session', error)
            window.location.reload()
          }
        })
      }
    },
    // Auth Methods
    // 404
    {
      path: '/*',
      redirect: '/'
    }
  ]
})

export default router
