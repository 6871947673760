<template>
  <div
    class="component-loading"
    :class="[visible ? 'is-active' : '']"
  >
    <div class="preloader" />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>
