<script setup>
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from 'vue'

import moment from 'moment'
import { DatePicker } from 'v-calendar'

const props = defineProps({
  modelValue: {
    type: [Object, String],
    default: () => moment()
  },
  lang: {
    type: String,
    default: 'es'
  },
  timeFormat: {
    type: String,
    default: 'HH:mm'
  },
  minuteSteps: {
    type: Number,
    default: 15,
    validator (value) {
      return [1, 5, 10, 15, 30].indexOf(value) !== -1
    }
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['input:modelValue'])

const options = reactive({
  lang: 'es',
  minuteSteps: 15
})
const internalValue = ref(null)

const updateCalendar = () => {
  if (props.modelValue) {
    const startDate = moment(props.modelValue).toDate()
    internalValue.value = startDate
  }
}

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    const isSameStart = moment(newValue).isSame(moment(oldValue))
    if (!isSameStart) {
      updateCalendar()
    }
  }
)

watch(
  internalValue,
  (newValue, oldValue) => {
    if (oldValue !== newValue) {
      emit('input:modelValue', moment(internalValue.value).format('YYYY-MM-DDTHH:mm:ss'))
    }
  }
)

onMounted(() => {
  options.lang = props.lang
  options.minuteSteps = props.minuteSteps

  updateCalendar()
})

</script>

<template>
  <DatePicker
    v-model="internalValue"
    mode="time"
    :update-on-input="true"
    :locale="options.lang"
    :minute-increment="options.minuteSteps"
    isRequired
    is24hr
    :popover="{ visibility: 'focus' }"
  >
    <template #default="{ inputValue, inputEvents }">
      <div class="control has-icons-right">
        <input
          class="input"
          :value="inputValue"
          :disabled="disabled"
          v-on="inputEvents"
        >
        <span class="icon is-small is-right">
          <i
            class="fa fa-clock"
          />
        </span>
      </div>
    </template>
  </DatePicker>
</template>
