import Noty from 'noty'

const icons = {
  alert: 'fa-bell',
  success: 'fa-check-circle',
  warning: 'fa-exclamation-circle',
  error: 'fa-exclamation-triangle',
  information: 'fa-info-circle'
}

const generateMessage = (type, message) => {
  return `<div class="noty_body-icon"><i class="fas ${icons[type]}"></i></div><div class="noty_body-content">${message}</div>`
}

const Alert = {
  alert (message) {
    this.message(message, 'alert')
  },
  success (message) {
    this.message(message, 'success')
  },
  warn (message) {
    this.message(message, 'warning')
  },
  error (message) {
    this.message(message, 'error')
  },
  info (message) {
    this.message(message, 'information')
  },

  message (message, type = 'information') {
    return new Noty({
      type: type,
      layout: 'topRight',
      theme: 'bootstrap-v4',
      text: generateMessage(type, message),
      timeout: '4000',
      progressBar: true,
      closeWith: ['click']
    }).show()
  },

  confirm (message, okText, cancelText, okCallback) {
    const type = 'warning'
    let backDrop = null
    const n = new Noty({
      type: type,
      text: generateMessage(type, message),
      layout: 'topRight',
      theme: 'bootstrap-v4',
      closeWith: [],
      buttons: [
        Noty.button('', 'noty_buttons-ok fas fa-check', () => {
          if (okCallback) {
            okCallback()
          }
          n.close()
        }, {
          id: 'okButton',
          'data-status': 'ok',
          'data-toggle': 'tooltip',
          'data-placement': 'right',
          title: okText
        }),
        Noty.button('', 'noty_buttons-cancel fas fa-times', () => {
          n.close()
        }, {
          id: 'cancelButton',
          'data-status': 'cancel',
          'data-toggle': 'tooltip',
          'data-placement': 'right',
          title: cancelText
        })
      ]
    }).on('onShow', () => {
      backDrop = document.createElement('div')
      backDrop.className = 'noty-backdrop'
      document.body.appendChild(backDrop)
    }).on('onClose', () => {
      document.body.removeChild(backDrop)
    }).show()

    return n
  }
}

export default Alert
