export default {
  decision: 'Decisión para despacho',
  dispatch: 'Despachar',
  alert_responsible: 'Debe seleccionar responsable para decisión:',
  alert_compromise: 'Debe ingresar fecha de compromiso para decisión:',
  alert_decision: 'Debe seleccionar decisión',
  select: 'Seleccione',
  events_not_found: 'No es posible despachar debido a que no existen eventos configurados',
  alert_validate_dispatch: 'Para continuar con el despacho, debe abrir la gestión y completar los campos obligatorios.',
  alert_validate_attachment: 'Para continuar con el despacho, debe abrir la gestión y adjuntar los documentos obligatorios',
  alert_validate_checklist: 'Para continuar con el despacho, debe abrir la gestión y chequear las actividades obligatorias',
  message_dispatch: 'Ticket Nº <%= contactId %>-<%= serialId %> ha sido procesado con éxito. \n',
  message_dispatch_date: 'Plazo ticket <%= docCreationDate %>'
}
