<template>
  <nav
    v-if="totalPages > 0"
    class="pagination is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <a
      class="pagination-previous"
      :disabled="isFirst"
      :title="text.first"
      @click="first()"
    >
      <span class="icon">
        <i class="fas fa-angle-double-left" />
      </span>
    </a>
    <a
      class="pagination-previous"
      :disabled="isFirst"
      :title="text.previous"
      @click="prev()"
    >
      <span class="icon">
        <i class="fas fa-angle-left" />
      </span>
    </a>
    <a
      class="pagination-next"
      :disabled="isLast"
      :title="text.next"
      @click="next()"
    >
      <span class="icon">
        <i class="fas fa-angle-right" />
      </span>
    </a>
    <a
      class="pagination-next"
      :disabled="isLast"
      :title="text.last"
      @click="last()"
    >
      <span class="icon">
        <i class="fas fa-angle-double-right" />
      </span>
    </a>

    <ul
      v-if="showPages"
      class="pagination-list"
    >
      <li
        v-for="page in pages"
        :key="page"
      >
        <a
          class="pagination-link"
          :class="[page === currentPage ? 'is-current' : '']"
          :aria-label="page"
          @click="setPage(page)"
        >{{ page }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pager',
  components: { },
  props: {
    currentPage: Number,
    totalPages: Number,
    showPages: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      recordQty: 5
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].awk.pager
    },
    pages () {
      const pages = []
      let startPage = 1
      let pagesToFirst = 0
      let pagesToEnd = 0

      pagesToFirst = this.currentPage - 1
      pagesToEnd = this.totalPages - this.currentPage

      if (this.currentPage - 2 > 0) {
        startPage = this.currentPage - 2
      }

      if (pagesToEnd < 2) {
        if (pagesToFirst > pagesToEnd) {
          startPage = this.currentPage - 2 - (2 - pagesToEnd)
        }
      }

      if (startPage < 1) {
        startPage = 1
      }

      for (let i = startPage; (i <= this.totalPages && (i - startPage) < 5); i++) {
        pages.push(i)
      }

      return pages
    },
    disabledFirst () {
      return {
        disabled: this.currentPage === 1
      }
    },
    disabledLast () {
      return {
        disabled: this.currentPage === this.totalPages
      }
    },
    isFirst () {
      return this.currentPage === 1
    },
    isLast () {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    isActive (page) {
      return {
        active: page === this.currentPage
      }
    },
    setPage (page) {
      this.$emit('search', { page: page })
    },
    first () {
      if (this.currentPage === 1) {
        return
      }
      this.setPage(1)
    },
    prev () {
      if (this.currentPage === 1) {
        return
      }
      this.setPage(this.currentPage - 1)
    },
    next () {
      if (this.currentPage === this.totalPages) {
        return
      }
      this.setPage(this.currentPage + 1)
    },
    last () {
      if (this.currentPage === this.totalPages) {
        return
      }
      this.setPage(this.totalPages)
    },
    onRecordQty () {
      this.$emit('recordQty', { recordQty: this.recordQty })
    }
  }
}
</script>

<style scoped="">
  .page-link {
    cursor: pointer;
  }
</style>
