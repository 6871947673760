export default {
  title_filters: 'Planificación',
  subtitle_filters: 'Utilice los filtros para acotar su búsqueda',
  lbl_responsible: 'Responsable',
  lbl_customer_type: 'Tipo de Cliente',
  lbl_document_type: 'Tipo de Identificador',
  lbl_customer_id: 'Identificador',
  lbl_customer_name: 'Nombre o Descriptor',
  lbl_segment: 'Segmento',
  lbl_position: 'Cargo',
  lbl_action_type: 'Tipo de Acción',
  btn_search: 'Buscar',
  view: 'Visualizar',

  options: {
    default: 'Seleccione',
    all: 'Todos',
    my_actions: 'Mis acciones',
    allCompany: 'Toda la empresa'
  },

  action_not_found: 'No se encontraron acciones',

  identifierFormat: {
    A: 'alphanumeric',
    N: 'numeric',
    R: 'rut'
  }
}
