const card = 'fas fa-id-card'

export default {
  expressions: {
    full: {
      decimal: /^(0|([1-9]\d{0,2})(\.?\d{3})*)?(,\d{0,2})?$/,
      email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      integer: /^(0|[1-9]\d{0,2}(\.?\d{3})*)$/,
      money: /^(0|[1-9]\d{0,2}(\.?\d{3})*)$/,
      name: /^[A-Za-zá-úÁ-Ú][A-Za-zá-úÁ-Ú'-]+([ A-Za-zá-úÁ-Ú][A-Za-zá-úÁ-Ú'-]+)*$/,
      phone: /^\+?\d{0,3} ?\d{4,14}$/,
      rut: /^(\d{1,3})\.?(\d{3})\.?(\d{3})-?([Kk0-9])$/,
      identifier: {
        rut: /^(\d{1,3})\.?(\d{3})\.?(\d{3})-?([Kk0-9])$/
      },
      ticket: /^[1-9][0-9]*(-[1-9])?$/
    },
    key: {
      alpha: /^[A-Za-z]*$/,
      alphanumeric: /^[0-9A-Za-z]*$/,
      calculation: /^[0-9,.,(,),*,/,+,^,\-,\b]*$/,
      decimal: /^(0|([1-9]\d{0,2})(\d{3})*)?(,\d{0,2})?$/,
      email: /^[\d\w._\W@]*$/,
      integer: /^(0|[1-9][0-9]*)$/,
      money: /^(0|[1-9][0-9]*)$/,
      name: /^[A-Za-z'\-\sÁ-Úá-ú]*$/,
      numeric: /^[0-9]*$/,
      phone: /^[+\d ]*$/,
      rut: /^[1-9][0-9]{0,8}[Kk0-9]?$/,
      ticket: /^[1-9][0-9]*-?[1-9]?$/,
      username: /^[0-9A-Za-z-._]*$/
    },
    template: {
      rut: '<%= value[1] %>.<%= value[2] %>.<%= value[3] %>-<%= value[4] %>',
      identifier: {
        rut: '<%= value[1] %>.<%= value[2] %>.<%= value[3] %>-<%= value[4] %>'
      }
    }
  },

  icons: {
    // format
    alphanumeric: card, // id
    calculation: 'fas fa-calculator', // process: CALCU
    check: 'fas fa-check-circle', // process: CHECK
    combo: 'fas fa-ellipsis-v', // process: COMBO
    comment: 'fas fa-comments', // process: GLOSA
    decimal: 'fas fa-divide', // process: VALOR
    date: 'fas fa-calendar', // process: FECHA
    email: 'fas fa-at', // customer, process: EMAIL
    hour: 'fas fa-clock', // process: HORA
    identifier: card,
    integer: 'fas fa-hashtag', // customer, process: NUMER
    list: 'fas fa-list', // process: LISTA
    map: 'fas fa-map-marked-alt', //  process: MAP
    money: 'fas fa-dollar-sign', // process: MONEY
    name: 'fas fa-font', // customer
    numeric: card, // id
    phone: 'fas fa-phone', // customer, process: FONO
    rut: card, // id, customer, process: RUT
    text: 'fas fa-font', // customer, process: TEXTO
    url: 'fas fa-link',
    username: 'fas fa-user',
    // types
    select: 'fas fa-ellipsis-v',
    group: 'fas fa-layer-group',
    join_group: 'fas fa-object-group'
  },

  invalidrut_alert: 'El rut ingresado no es válido',
  invalidinput_alert: 'El dato ingresado tiene un formato incorrecto'
}
