import { defineStore } from 'pinia'
import text from '../text'

const defaultLanguage = 'es'

export const useTextStore = defineStore('text', {
  state: () => {
    return {
      language: defaultLanguage,
      globalText: text
    }
  },
  getters: {
    current (state) {
      return state.globalText[state.language]
    },
    awk (state) {
      return state.globalText[state.language].awk
    }
  }
})
