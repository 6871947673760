import home from './home'
import customer from './customer'
import reminder from './reminder'
import state from './state'
import type from './type'
import subtype from './subtype'
import contact from './contact'
import definition from './definition'

export default {
  home: home,
  customer: customer,
  reminder: reminder,
  state: state,
  type: type,
  subtype: subtype,
  contact: contact,
  definition: definition
}
