export default {
  title: 'Búsqueda de clientes',
  customer_type: 'Tipo de cliente',
  document_type: 'Tipo de Identificador',
  customer_id: 'Identificador',
  placeholder_customer_id: 'Ingrese',
  name: 'Nombre',
  placeholder_customer_name: 'Ingrese',

  customer_selection: 'Seleccione cliente',
  customers_not_found: 'No se encontraron clientes',

  btn_search: 'Buscar'
}
