export default {
  title_filters: 'Filtros',
  subtitle_filters: 'Utilice los filtros para acotar su búsqueda',
  lbl_customer_type: 'Tipo de Cliente',
  lbl_document_type: 'Tipo de Identificador',
  lbl_customer_id: 'Identificador',
  lbl_customer_name: 'Nombre o Descriptor',
  btn_search: 'Buscar',
  btn_searching: 'Buscando',
  customer_placeholder: 'Imagen cliente'
}
