const counters = {}

const Counter = {
  increase (name) {
    if (counters[name] || counters[name] === 0) {
      counters[name] = counters[name] + 1
    } else {
      counters[name] = 0
    }
    return counters[name]
  },
  current (name) {
    return counters[name]
  },
  set (name, value) {
    const intValue = value ?? 0
    counters[name] = intValue
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$counter = Counter
    Vue.counter = Counter
  }
}
