import { defineStore } from 'pinia'

export const useErrorStore = defineStore('error', {
  state: () => {
    return {
      errors: []
    }
  },
  actions: {
    addError (error) {
      if (!error.response) {
        return
      }
      if (!error.response.headers['x-tx-id']) {
        return
      }
      if (error.response.status === 500 && error.response.data && error.response.data.errorNum && error.response.data.errorNum === 404) {
        return
      }
      if (error.response && error.response.headers['x-tx-id']) {
        this.errors.push({
          date: new Date(),
          status: error.response.status,
          url: error.config.url,
          method: error.config.method,
          txId: error.response.headers['x-tx-id'],
          data: error.response.data
        })
      }
    }
  }
})

if (window) {
  window.lastErrors = (x) => {
    const errorStore = useErrorStore()
    console.log('Last errors', errorStore.errors.slice((x ?? 100) * -1))
  }
}
