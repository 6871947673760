export default {
  title_new_contact: 'Nuevo contacto',
  title_add_contact: 'Agregar contacto',
  title_contact_info: 'Datos del contacto',
  title_contactability_info: 'Información de contactabilidad',
  title_information: 'Información',

  subtitle_step1: 'Búsqueda de contacto',
  subtitle_step2a: 'Confirmación de contacto',
  subtitle_step2b: 'Crear nuevo contacto',

  notification_contact_found: 'El contacto seleccionado ya existe, si lo deseas asociar a este cliente presiona el botón <b>Agregar</b>',

  btn_next: 'Siguiente',
  btn_add: 'Agregar',
  btn_cancel: 'Cancelar',

  not_selected: 'Ninguno',
  select: 'Seleccione',
  lbl_contact_type: 'Tipo de contacto',

  avatar: 'Avatar',
  file: 'Archivo',
  ok: 'Aceptar',
  cancel: 'Cancelar',
  close: 'Cerrar',

  alert_assign_contact_success: 'El contacto ha sido asociado satisfactoriamente',
  alert_search_contact_info: 'Ingresa todos los campos para realizar la búsqueda de contacto',
  create_contact_success: 'El contacto ha sido creado satisfactoriamente',
  contact_requireddata_alert: 'Debe ingresar todos los campos resaltados para continuar',
  confirm_close_create_contact: '¿Está seguro que desea cerrar la interfaz de creación? Se perderán los cambios realizados',
  update_contactinfo_success: 'La información del contacto ha sido actualizada satisfactoriamente',
  update_contactcontactability_success: 'La información de contacto del contacto ha sido actualizada satisfactoriamente',
  update_contactvariable_success: 'La información personalizada del contacto ha sido actualizada satisfactoriamente',

  confirm: {
    ok: 'Sí',
    cancel: 'No'
  },

  btn_status: 'Cambiar estado',
  status_question: '¿Está seguro que desea cambiar el estado?',
  status_options: {
    enable: 'Activar',
    disable: 'Desactivar'
  },
  update_contactstatus_success: 'El estado del contacto ha sido actualizado satisfactoriamente',
  update_contactstatus_error: 'Ha ocurrido un error al actualizar el estado del contacto'
}
