export default [
  {
    path: '',
    name: 'MenuLauncher',
    component: () => import('@/components/MenuLauncher')
  },
  {
    path: 'to-do',
    name: 'ToDoFinder',
    component: () => import(/* webpackChunkName: "todo" */ '@/components/todo/ToDoFinder')
  },
  {
    path: '/ticket/:contactId(\\d+)/:serialId(\\d+)/:flowCode/:taskId',
    name: 'TicketDetail',
    component: () => import(/* webpackChunkName: "ticket" */ '@/components/ticket/TicketDetail')
  },
  {
    path: '/ticket/:contactId(\\d+)/:serialId(\\d+)',
    name: 'TicketDetailSimple',
    component: () => import(/* webpackChunkName: "ticket" */ '@/components/ticket/TicketDetail')
  },
  {
    path: '/ticket/backup/:contactId(\\d+)/:serialId(\\d+)',
    name: 'TicketDetailBackup',
    component: () => import(/* webpackChunkName: "ticket" */ '@/components/ticket/TicketDetail')
  },
  {
    path: '/ticket/new/:campaignId',
    name: 'TicketNew',
    component: () => import(/* webpackChunkName: "ticket" */ '@/components/ticket/TicketDetail')
  },
  {
    path: '/ticket/replicate/:contactId(\\d+)/:serialId(\\d+)/:campaignId(\\d+)',
    name: 'TicketReplicate',
    component: () => import(/* webpackChunkName: "ticket" */ '@/components/ticket/TicketDetail')
  },
  {
    path: 'history',
    name: 'HistoryFinder',
    component: () => import(/* webpackChunkName: "history" */ '@/components/history/v2/HistoryFinder')
  },
  {
    path: 'knowledge',
    name: 'ArticleViewHome',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/CoverPage')
  },
  {
    path: 'knowledge/category',
    name: 'CategoryDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/CategoryDetail')
  },
  {
    path: 'knowledge/tag',
    name: 'TagDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/TagDetail')
  },
  {
    path: 'knowledge/finder',
    name: 'FinderDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/FinderDetail')
  },
  {
    path: 'knowledge/:articleId',
    name: 'ArticleDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/ArticleDetail')
  },
  {
    path: 'knowledge/category/:categoryId',
    name: 'CategoryArticleDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/CategoryArticleDetail')
  },
  {
    path: 'knowledge/tag/:tagId',
    name: 'TagArticleDetail',
    component: () => import(/* webpackChunkName: "knowledge" */ '@/components/knowledge/TagArticleDetail')
  },
  {
    path: 'create',
    name: 'CreateHome',
    component: () => import(/* webpackChunkName: "create" */ '@/components/create/CreateHome')
  },
  {
    path: 'customer',
    name: 'CustomerHome',
    component: () => import(/* webpackChunkName: "customer" */ '@/components/customer/CustomerFinder')
  },
  {
    path: '/customer/:codCustomerType/:codCustomerId/:customerId',
    name: 'CustomerDetail',
    component: () => import(/* webpackChunkName: "customer" */ '@/components/customer/CustomerDetail')
  },
  {
    path: '/contact/:codContactType/:codContactId/:contactId',
    name: 'ContactDetail',
    component: () => import(/* webpackChunkName: "contact" */ '@/components/contact/ContactDetail')
  },
  {
    path: 'calendar',
    component: () => import(/* webpackChunkName: "home" */ '@/components/KeepAliveContainer'),
    children: [
      {
        path: '',
        name: 'CalendarHome',
        component: () => import(/* webpackChunkName: "visitPlan" */ '@/components/visitPlan/calendar/CalendarHome')
      },
      {
        path: 'visit-plan',
        name: 'VisitPlanHome',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/components/visitPlan/toPlan/PlanHome')
      }
    ]
  }
]
