<template>
  <div
    class="dropdown"
    :class="[isActive ? 'is-active' : '']"
  >
    <div
      class="dropdown-trigger is-cursor-pointer"
      :class="dropdownClasses"
      @click.prevent.stop="onToggle"
    >
      <span class="icon-text">
        <span>{{ internalDescription }} </span>
        <span
          v-if="hasIcon"
          class="icon"
        >
          <i :class="internalIcon" />
        </span>
      </span>
    </div>

    <div
      v-if="items.length > 0"
      id="dropdown-menu"
      class="dropdown-menu has-text-weight-normal"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          v-if="title"
          class="dropdown-item has-text-weight-bold is-cursor-default"
          @click.prevent.stop
        >{{ title }}</a>

        <div class="dropdown-item">
          <p class="control has-icons-left has-icons-right">
            <input
              class="input"
              placeholder="Buscar subflujo"
              @click.prevent.stop
            >
            <span class="icon is-small is-left">
              <i class="fas fa-search" />
            </span>
          </p>
        </div>

        <template v-for="item in items">
          <hr
            v-if="!isFirst(item.code)"
            :key="`div${item.code}`"
            class="dropdown-divider my-0"
          >
          <a
            :key="`item${item.code}`"
            class="dropdown-item"
            @click="onSelected(item.code)"
          >
            {{ item.name }}
          </a>
        </template>

        <a
          class="dropdown-item is-info"
          @click.prevent.stop="onToggleNewSubFlow"
        >
          Añadir nuevo subflujo
        </a>
      </div>
    </div>

    <div
      v-else
      id="dropdown-menu"
      class="dropdown-menu has-text-weight-normal"
      role="menu"
    >
      <div class="dropdown-content">
        <a class="dropdown-item">
          No se encontraron registros
        </a>
        <a
          class="dropdown-item is-info"
          @click.prevent.stop="onToggleNewSubFlow"
        >
          Añadir nuevo subflujo
        </a>
      </div>
    </div>

    <div
      v-if="flagNew"
      id="dropdown-menu"
      class="dropdown-menu has-text-weight-normal"
      role="menu"
    >
      <div class="dropdown-content">
        <a
          class="dropdown-item has-text-weight-bold is-cursor-default"
          @click.prevent.stop
        >Nombre del Subflujo</a>

        <div class="dropdown-item">
          <input
            v-model="code"
            class="input"
            @click.prevent.stop
          >
          <input
            v-model="name"
            class="input"
            @click.prevent.stop
          >
        </div>

        <div class="dropdown-item">
          <a @click.prevent.stop="onToggleNewSubFlow">Cancelar</a>
          <a @click.prevent.stop="onSave">Guardar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { find, first } from 'lodash'
import axios from 'axios'
import config from '@/config'

export default {
  name: 'DropdownSubFlow',
  model: {
    events: 'input'
  },
  props: [
    'value',
    'description',
    'isButton',
    'hasIcon',
    'icon',
    'title',
    'items',
    'flow'
  ],
  data () {
    return {
      internalValue: '',
      isActive: false,
      flagNew: false,
      code: '',
      name: ''
    }
  },
  computed: {
    dropdownClasses () {
      if (this.isButton) {
        return [
          'button is-fullwidth',
          this.isActive ? 'is-dark' : 'is-light'
        ]
      }
      return ''
    },
    internalDescription () {
      const item = find(this.items, { key: this.value })
      return this.description || (item ? item.value : '')
    },
    internalIcon () {
      return this.icon || 'fas fa-chevron-down'
    }
  },
  watch: {
    value () {
      this.internalValue = this.value
    },
    internalValue () {
      this.$emit('input', this.internalValue)
    }
  },
  created () {
    this.internalValue = this.value
  },
  methods: {
    onDouble (event) {
      console.log(event)
    },
    onToggle () {
      this.isActive = !this.isActive
      if (this.isActive) {
        const callback = e => {
          this.isActive = false
          window.removeEventListener('click', callback)
        }
        window.addEventListener('click', callback)
      }
    },
    isFirst (key) {
      return first(this.items).key === key
    },
    onSelected (key) {
      this.internalValue = key
    },
    onToggleNewSubFlow () {
      this.flagNew = !this.flagNew
    },
    onSave () {
      const request = {
        code: this.code,
        name: this.name
      }

      const loading = this.$loading.show()

      axios({
        method: 'post',
        url: `${config.flow.endpoint}/flow/${this.flow.code}/subflow`,
        headers: {
          Authorization: `Bearer ${this.$session.apiKey()}`,
          'X-Session-Token': this.$session.id()
        },
        data: request
      })
        .then(response => {
          this.$alert.success('this.text.alert_task_save_ok')
        })
        .catch(this.$handle().error)
        .finally(() => {
          this.$loading.remove(loading)
        })
    }
  }
}
</script>
