import Vue from 'vue'

const tabsBus = new Vue()

const tabsPlugin = {
  open (eventData) {
    tabsBus.$emit('openTab', eventData)
  },
  onOpen (callback) {
    tabsBus.$on('openTab', callback)
  },
  close (eventData) {
    tabsBus.$emit('closeTab', eventData)
  },
  onClose (callback) {
    tabsBus.$on('closeTab', callback)
  },
  offClose (callback) {
    tabsBus.$off('closeTab', callback)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$tabs = tabsPlugin
    Vue.tabs = tabsPlugin
  }
}
