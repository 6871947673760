import config from '@/config'

const signalR = require('@microsoft/signalr')

let isAuthenticated = false
let session = false
let authenticateCallback = null
const authenticateError = 'Authenticate Error'

const connection = new signalR.HubConnectionBuilder()
  .withUrl(config.hub.notification.endpoint, { accessTokenFactory: () => session.apiKey() })
  .withAutomaticReconnect()
  .configureLogging(process.env.NODE_ENV === 'production' ? signalR.LogLevel.Error : signalR.LogLevel.Information)
  .build()

connection.onreconnected(() => {
  console.assert(connection.state === signalR.HubConnectionState.Connected)
})

connection.on('Authenticate', result => {
  isAuthenticated = result
  if (authenticateCallback && result) {
    authenticateCallback()
  }
})

const hubClient = {
  connect ($session) {
    session = $session
    if (!this.isConnected()) {
      connection.start()
        .then(() => {
          isAuthenticated = true
          if (authenticateCallback) {
            authenticateCallback()
          }
        })
        .catch(e => console.error('Connection Error', e))
    } else if (!this.isAuthenticated()) {
      connection
        .invoke('Authenticate', session.apiKey(), session.id())
        .catch(e => {
          console.error(authenticateError, e)
        })
    }
  },
  disconnect () {
    connection.stop()
  },
  isAuthenticated () {
    return isAuthenticated
  },
  isConnected () {
    return connection.state === signalR.HubConnectionState.Connected
  },
  on (method, callback) {
    connection.on(method, callback)
  },
  onAuthenticated (callback) {
    authenticateCallback = callback
  },
  off (method) {
    connection.off(method)
  },
  invoke (...args) {
    return connection.invoke(...args)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$hubClient = hubClient
    Vue.hubClient = hubClient
  }
}
