export default {
  label_cancel: 'Cancelar',
  label_clear: 'Limpiar',
  label_today: 'Hoy',
  label_now: 'Ahora',
  label_validate: 'Confirmar',
  label_from: '',
  label_to: '',

  format_timeline: 'dddd[,] DD [de] MMMM',
  format_timeline_year: 'dddd[,] DD [de] MMMM [de] YYYY',
  format_datetime: 'DD/MM/YYYY HH:mm',
  format_shorttime: 'HH:mm'
}
